import React, {useEffect} from 'react';
import Highcharts from 'highcharts';
import { useIntl } from 'react-intl';

const markerFilters = {
    'All':'All',
    'Stress severo': 'RED',
    'Inicia stress': 'YELLOW',
    'Adecuado': 'GREEN',
    'Exceso de agua': 'BLUE'
}

function PiesContainer (props){
    return (
        <div className="mdl-grid mdl-grid--no-spacing pie-box--container" >

            <div className="mdl-cell mdl-cell--12-col">
                <div className='mdl-grid'>
                    {props.children}
                </div>
            </div>

        </div>
    );
}


function PieBox (props){
    const intl = useIntl();
    return ( props.insideMap ?
        <Pie
            isGraphPieBox={props.isGraphPieBox}
            setGridData={props.setGridData}
            data={props.data}
            name={props.name}
            insideMap={props.insideMap}
            setFilter={props.setFilter}/> :
        <div className="mdl-cell mdl-cell--4-col mdl-cell--8-col-tablet mdl-cell--4-col-phone mdl-shadow--2dp">
            <div className='mdl-grid pie-box--inner-ctn'>
                <div className='mdl-cell mdl-cell--12-col pie-box--header'>
                    <div className='mdl-grid mdl-grid--no-spacing'>
                        <h6 className='mdl-cell mdl-cell--6-col pie-box--title'>
                            {props.name} {intl.formatMessage({id:'app.reports.piebox', defaultMessage:'Status'})}
                        </h6>
                        <div className='mdl-cell mdl-cell--6-col pie-box--action'>
                            {/* <i className='material-icons' style={{cursor:'pointer'}}>
                                refresh
                            </i> */}
                        </div>
                    </div>
                </div>
                <div className='mdl-cell mdl-cell--12-col pie-box--stats'>
                    <div className='mdl-grid mdl-grid--no-spacing'>
                        {/* <div className='mdl-cell'>
                            <div className='mdl-grid mdl-grid--no-spacing'>
                                <div className='mdl-cell mdl-cell--12-col stat-figure'>
                                    {props.normal}
                                </div>
                                <div className='mdl-cell mdl-cell--12-col stat-title'>
                                    Normal
                                </div>
                            </div>
                        </div> */}
                        {props.data !== undefined ? props.data.map(p => 
                            <div className={`mdl-cell mdl-cell--${props.cols}-col mdl-cell--${props.cols-1}-col-tablet mdl-cell--${props.cols-2}-col-phone`} key={p.name}>
                                <div className='mdl-grid mdl-grid--no-spacing'>
                                    <div className='mdl-cell mdl-cell--12-col stat-figure'>
                                        {p.y}
                                    </div>
                                    <div className='mdl-cell mdl-cell--12-col stat-title'>
                                        {p.name}
                                    </div>
                                </div>
                            </div>
                        ):null}
                        {props.all ? 
                        <div className={`mdl-cell mdl-cell--${props.cols}-col mdl-cell--${props.cols-1}-col-tablet mdl-cell--${props.cols-2}-col-phone`} key='Total'>
                            <div className='mdl-grid mdl-grid--no-spacing'>
                                <div className='mdl-cell mdl-cell--12-col stat-figure'>
                                    {props.all}
                                </div>
                                <div className='mdl-cell mdl-cell--12-col stat-title'>
                                   {intl.formatMessage({id:'app.reports.piebox.total', defaultMessage:'Total'})}
                                {/* <button className='ag-link-button' onClick={(e) => props.setGridData(e, 'All')}>{'Total'}</button> */}
                                </div>
                            </div>
                        </div> : null}

                    </div>
                </div>
                <div className='mdl-cell mdl-cell--12-col pie-box--content'>
                    <Pie
                    isGraphPieBox={props.isGraphPieBox}
                    data={props.data}
                    name={props.name}
                    setGridData={props.setGridData}
                    insideMap={props.insideMap}
                    setFilter={props.setFilter}
                    />
                </div>
            </div>
        </div>
    );
}


function Pie (props){

    let filterValue = 'All';

    function handleClick(e){
        const name = e.point.id;
        props.setGridData(e, name);

        if (filterValue === name){
            filterValue = 'All';
        }else{
            filterValue = name;
        }
        props.setFilter(markerFilters[filterValue])
    }

    useEffect(() => {

        Highcharts.chart(props.name, {
            chart: {
            backgroundColor: props.insideMap && null,
                type: 'pie'
            },
            credits: {
                enabled: false
            },
            title: null,
            tooltip: {
                pointFormat: '{series.name}: <b> {point.percentage: .1f}% </b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: props.insideMap ? '70%': null ,
                    dataLabels: {
                        style:{
                            fontSize: 15
                        },
                        enabled: props.insideMap ? true : false,
                        // enabled: false
                        format: '<b>{point.name}</b><br>{point.percentage:.1f} %',
                        distance: 25
                            
                    },
                    showInLegend: props.insideMap ? false : true
                } 
            },
            series: [{
                name: 'Status',
                colorByPoint: true,
                data: props.data,
                events: props.isGraphPieBox && {
                    click: handleClick
                }
            }],
            exporting:{
                enabled: false
            }
        });

    }, [props.data]);
    
    return (
        <div id={props.name} style={{width:'100%', height:'100%'}}>

        </div>
    );
}


export default PieBox;
export {PiesContainer, Pie};