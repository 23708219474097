import React, { Component } from 'react';


class Profile extends Component {
  render() {

    return (<div>Profile </div>);
  }
}




export default Profile;