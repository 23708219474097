import React, { useState } from 'react';
import { getAuthToken, BASEURI } from '../utils';
import { useEffect } from 'react';
import { StationContext } from './Scheduler';
import { useContext } from 'react';
import { Label, Error } from "@progress/kendo-react-labels";
import { TextArea } from "@progress/kendo-react-inputs";
import { FormElement, Field } from "@progress/kendo-react-form";
import { DatePicker, DateTimePicker } from "@progress/kendo-react-dateinputs";

import {
  TitleEditor,
  DeviceEditor,
  SectorEditor,
  RecurrenceEditor,
  DurationEditor,
  PrecipitationEditor
} from "./Editors";
import { useIntl } from 'react-intl';


export const CustomFormEditor = props => {
  const intl = useIntl();
  const [device, setDevice] = useState(null);
  const [sectors, setSectors] = useState([]);
  const stations = useContext(StationContext);

  useEffect(() => {

    if (device) {
      let url = BASEURI + `/api/scheduler/sectors/${device.serial}`,
        options = {
          method: 'GET',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        };

      getAuthToken()
        .then(token => token)
        .catch(token => token)
        .then(token => {

          //no token exists or all tokens are expired
          if (token === false) {
            return false;
          }

          options.headers.Authorization = `Bearer  ${token}`;

          fetch(url, options)
            .then((response) => {
              return response.json();
            })

            .then((sectors_list) => {
              setSectors(sectors_list);
            })

            .catch(error => {
              console.error('Error:', error.code);
            });
        });
    }

  }, [device])

  return <FormElement >

    <div className="k-form-field">
      <Label>{intl.formatMessage({ id: "app.scheduler.taskModel.title", defaultMessage: 'Title' })}</Label>
      <div className="k-form-field-wrap">
        <Field name={"title"} component={TitleEditor} />
        {props.errors.title && <Error>{props.errors.title}</Error>}
      </div>
    </div>

    <div className="k-form-field">
      <Label>{intl.formatMessage({ id: 'app.scheduler.taskModel.start', defaultMessage: 'Start' })}</Label>
      <Field
        name={"start"}
        component={props.startEditor || DatePicker}
        as={DateTimePicker}
        rows={1}
        width={"140px"}
        format="t"
      />
      {props.errors.start && <Error>{props.errors.start}</Error>}
      <div>
        <Label>{intl.formatMessage({ id: 'app.scheduler.taskModel.duration', defaultMessage: 'Duration (H)' })}</Label>

        <Field
          name={"duration"}
          component={DurationEditor}
        />
        {props.errors.duration && <Error>{props.errors.duration}</Error>}
      </div>

      <div>
        <Label>{intl.formatMessage({ id: 'app.scheduler.taskModel.precip', defaultMessage: 'Precipitation (mm)' })}</Label>

        <Field
          name={"precip"}
          component={PrecipitationEditor}
        />
      </div>
    </div>

    <div className="k-form-field">
      <Label>{intl.formatMessage({ id: "app.scheduler.taskModel.frequency", defaultMessage: 'Frequency' })}</Label>
      <div className="k-form-field-wrap">
        <Field name={"recurrence_rule"} component={RecurrenceEditor} rows={1} />
      </div>
    </div>

    <div className="k-form-field">
      <Label>{intl.formatMessage({ id: 'app.scheduler.taskModel.station', defaultMessage: 'Station' })}</Label>
      <div className="k-form-field-wrap">
        <Field
          name="device"
          setDevice={setDevice}
          devices={stations}
          component={DeviceEditor} />
      </div>
      {props.errors.device && <Error>{props.errors.device}</Error>}
    </div>

    <div className="k-form-field">
      <Label>{intl.formatMessage({ id: "app.scheduler.events.filter.sector", defaultMessage: 'Sector' })}</Label>
      <div className="k-form-field-wrap">
        <Field name="sector" sectors={sectors} component={SectorEditor} />
        {props.errors.sector && <Error>{props.errors.sector}</Error>}
      </div>
    </div>

    <div className="k-form-field">
      <Label>{intl.formatMessage({ id: "app.scheduler.taskModel.notes", defaultMessage: 'Note' })}</Label>
      <div className="k-form-field-wrap">
        <Field name={"description"} default=' ' component={TextArea} rows={1} />
      </div>
    </div>
  </FormElement>
};