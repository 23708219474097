import React, { Component } from 'react';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';

class VPDForm extends Component {


	setInitialValues() {
		this.setState(this.props.initialValues);
		this.setState(this.props.options);
	}

	itemRender(li, itemProps) {
		const itemChildren = <div> {li.props.children} <div style={{ color: "#00F" }}> {itemProps.dataItem.device.name} [{itemProps.dataItem.port}]</div> </div>;
		return React.cloneElement(li, li.props, itemChildren);
	}

	graphTypes = {
		line: { text: 'Line', value: 'line' },
		column: { text: 'Bar', value: 'column' }
	}

	componentDidUpdate() {
		console.log('new sensor select value: ', this.props.graph.variables.temp.sensor);
	}

	render() {

		return (
			<div className='modal-form-inputs-wrap'>
				<div className="modal-form-inputs-wrap-row">
					<MultiSelect key='temp-select'
						listNoDataRender={this.props.loadingSensors ? this.props.noData : null}
						name='temp_sensor'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.temp', defaultMessage: 'TEMP' })}
						data={this.props.sensors}
						value={this.props.graph.variables.temp.sensor}
						textField='name'
						dataItemKey='id'
						itemRender={this.itemRender}
						onChange={this.props.handleChange}
						filterable={true}
						onFilterChange={this.props.handleFilterChange}
						style={{ height: 'auto' }}
					/>
					<DropDownList
						key='temp-extract-select'
						name='temp_extract'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.tempExtract', defaultMessage: 'TEMP SENSOR EXTRACT' })}
						data={this.props.extracts}
						value={this.props.graph.variables.temp.extract}
						textField='description'
						dataItemKey='id'
						onChange={this.props.handleChange} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<MultiSelect
						listNoDataRender={this.props.loadingSensors ? this.props.noData : null}
						key='humidity-select'
						name='humidity_sensor'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.humidity', defaultMessage: 'HUMIDITY' })}
						data={this.props.sensors}
						value={this.props.graph.variables.humidity.sensor}
						textField='name'
						dataItemKey='id'
						itemRender={this.itemRender}
						onChange={this.props.handleChange}
						filterable={true}
						onFilterChange={this.props.handleFilterChange}
						style={{ height: 'auto' }}
					/>
					<DropDownList
						key='humidity-extract-extract'
						name='humidity_extract'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.humidityExtract', defaultMessage: 'HUMIDITY SENSOR EXTRACT' })}
						data={this.props.extracts}
						value={this.props.graph.variables.humidity.extract}
						textField='description'
						dataItemKey='id'
						onChange={this.props.handleChange} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<DropDownList
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.chartType', defaultMessage: 'CHART TYPE' })}
						name='_type'
						data={[{ text: 'Line', value: 'line' }, { text: 'Bar', value: 'column' }]}
						textField='text'
						dataItemKey='value'
						value={this.graphTypes[this.props.graph._type]}
						onChange={this.props.handleChange} />
					<Input
						type='color'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.color', defaultMessage: 'COLOR' })}
						name='color'
						// data={this.state.color}
						value={this.props.graph.color}
						onChange={this.props.handleChange} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<Input
						required={true}
						name='label'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.label', defaultMessage: 'LABEL' })}
						value={this.props.graph.label}
						onChange={this.props.handleChange} />
					<Input
						name='axis_min'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.axisMin', defaultMessage: 'AXIS MIN' })}
						value={this.props.graph.axis_min !== null ? this.props.graph.axis_min : ''}
						onChange={this.props.handleChange} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<Input
						name='axis_max'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.axisMax', defaultMessage: 'AXIS MAX' })}
						value={this.props.graph.axis_max !== null ? this.props.graph.axis_max : ''}
						onChange={this.props.handleChange} />
					<Input
						required={true}
						name='y_axis_legend'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.yAxisLegend', defaultMessage: 'Y AXIS LEGEND' })}
						value={this.props.graph.y_axis_legend}
						onChange={this.props.handleChange} />
				</div>
			</div>
		);
	}
}

export default VPDForm;