import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { FormattedMessage } from 'react-intl';
import login_bg from '../login_bg.jpeg';
import logo from '../logo.png';
import bg1 from '../bg_1.jpg';
import bg2 from '../bg_2.jpg';
import bg3 from '../bg_3.jpg';
import loading from '../loading_white.svg';
import { Navigate } from 'react-router-dom';



class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: '',
			backgroundList: [login_bg, bg1, bg2, bg3],
			index: 0
		};

		this.handlChange = this.handlChange.bind(this);
	}

	onClick = () => {
		this.props.login(this.state);

	}

	onTouchStart = () => {
		// onTouchStart is only used/handled because of the webkit iOS issue of not
		// firing onClick when the tap is not quick enough.
		this.props.login(this.state);
	}

	componentDidMount() {
		if (this.usernameInput)
			this.usernameInput.focus()

		this.randomBackground()
	}

	handlChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	}


	handleRef = (input) => {
		this.usernameInput = input;
	}

	randomBackground = () => {
		let index = Math.floor(Math.random() * 4);
		this.setState({
			index: index
		})

	}

	handleKeySubmit = (e) => {
		if (e.key === "Enter") {
			this.onClick()
		}

	}

	render() {
		return (
			this.props.loggedin ? <Navigate to='/' /> :

				<div className="mdl-layout mdl-js-layout mdl-layout--fixed-header" style={{
					backgroundImage: `url("${this.state.backgroundList[this.state.index]}")`,
					backgroundSize: 'cover',
					backgroundPosition: 'center'
				}}>
					<main className="mdl-layout__content login-container" >
						<div className="page-content ">

							<div className="login-card mdl-card mdl-shadow--2dp" style={{ paddingTop: '0' }}>
								<img src={logo} style={{ width: '200px', marginRight: '20px' }} alt="agviewer"></img>
								<div style={{ color: 'red' }}>

								</div>



								<div className="mdl-textfield mdl-js-textfield" style={{ paddingTop: '0' }}>

									<Input ref={(input) => this.handleRef(input)} onKeyUp={(e) => this.handleKeySubmit(e)} value={this.username} onChange={this.handlChange} name='username' label={<FormattedMessage id='app.login.username' defaultMessage='USERNAME' />} />
								</div>
								<div className="mdl-textfield mdl-js-textfield">
									<Input value={this.username} onKeyUp={(e) => this.handleKeySubmit(e)} onChange={this.handlChange} name='password' type={'password'} label={<FormattedMessage id='app.login.password' defaultMessage='PASSWORD' />} />

								</div>

								<div className="mdl-card__supporting-text">

								</div>

								{/* Error message */}
								<div>
									<p style={{ color: 'red' }}>{this.props.errorMsg}</p>
								</div>

								<div className="mdl-card__actions mdl-card--border">
									{/* <a href="/create_account/" className="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect">
									Sign Up
								</a> */}
									<div className="mdl-layout-spacer">
									</div>
									<a
										onTouchStart={this.onTouchStart}
										onClick={this.onClick}
										style={{ width: '100px', height: 36 }}
										className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-primary mdl-js-button mdl-js-ripple-effect"
									>
										{this.props.loggingIn ?
											<img style={{ width: '20px' }} src={loading} alt='loading' /> :
											<FormattedMessage id='app.login.login' defaultMessage='Login' />}
									</a>
								</div>
							</div>

						</div>
					</main>
				</div>);
	}
}

export default Login;
