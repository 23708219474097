import React from 'react';


class Tab extends React.Component {

  render(){

    let activeClass = this.props.isActive ? "is-active": "";
    return (this.props.isCurrentPage ? 
      <a 
        href={'#'+this.props.href}
        onClick={(e) => this.props.onClick(e, this.props.id)}
        className={"mdl-layout__tab " + activeClass }
      >
        {this.props.tabName}
      </a>
      :
      null
    );
  }
}


export default Tab;