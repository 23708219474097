import React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { Switch } from '@progress/kendo-react-inputs';
import { useIntl } from 'react-intl';

export default function FilterOptions(props) {
    const intl = useIntl();

    function itemRender(li, itemProps) {
        const itemChildren = <div> {li.props.children} <div style={{ color: "#00F" }}> {itemProps.dataItem.device.name} [{itemProps.dataItem.port}]</div> </div>;
        return React.cloneElement(li, li.props, itemChildren);
    }

    return <div className="p-4">
        <div className='w-full p-2'>
            <DropDownList
                name='device'
                label={intl.formatMessage({ id: "app.scheduler.events.station", defaultMessage: 'Station' })}
                dataItemKey='serial'
                textField='name'
                valueField='serial'
                data={props.stations}
                value={props.filterVars.device}
                onChange={props.handleChange}
                filterable={true}
                onFilterChange={props.handleStationFilterChange}
                style={{ width: '100%' }}
            />
        </div>
        <div className='w-full p-2'>
            <DropDownList
                name='sector'
                label={intl.formatMessage({ id: "app.scheduler.events.filter.sector", defaultMessage: 'Sector' })}
                data={props.sectors}
                textField='name'
                value={props.filterVars.sector}
                onChange={props.handleChange}
                itemRender={itemRender}
                style={{ width: '100%' }}
            />
        </div>
        <div className='w-full p-2'>
            <DateRangePicker
                endDateInput={(props) => { return <div></div> }}
                name={'daterange'}
                value={props.range}
                onChange={props.handleDateChange}
                calendarSettings={{ views: 1 }}
                label='Range'
                style={{ width: '100%' }}
            />
        </div>
        <div className="flex justify-between pb-4 p-2  text-gray-700 ">
            <div>{intl.formatMessage({ id: "app.scheduler.events.startEvent", defaultMessage: 'Start Events' })}</div>
            <Switch
                name='start'
                value={props.filterVars.start}
                onChange={props.handleChange}
                defaultChecked={props.filterVars.start}
                className='pr-4'
            />
        </div>
        <div className="flex justify-between pb-4 p-2 text-gray-700">
            <div>{intl.formatMessage({ id: "app.scheduler.events.stopEvents", defaultMessage: 'Stop Events' })}</div>
            <Switch
                name='stop'
                value={props.filterVars.stop}
                onChange={props.handleChange}
                defaultChecked={props.filterVars.stop}
                className='pr-4'

            />
        </div>
        <div className="flex justify-between pb-4 p-2 text-gray-700">
            <div>{intl.formatMessage({ id: "app.scheduler.events.errors", defaultMessage: 'Errors' })}</div>
            <Switch
                name='error'
                value={props.filterVars.error}
                onChange={props.handleChange}
                defaultChecked={props.filterVars.error}
                className='pr-4'
            />
        </div>
    </div>
}