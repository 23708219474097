import React, { useState, useEffect, useMemo, useRef } from 'react';
import { getAuthToken, BASEURI } from '../utils';
import PieBox, { PiesContainer } from './PieBox';
import StationGrid, { GridContainer } from './AdminStationGrid';
import GraphGrid from './AdminGraphGrid';
import UserGrid from './AdminUserGrid';
import GMap from './Maps';
import ConfirmDeleteDialog from '../general/ConfirmDeleteDialog';
import WeatherReport from './reports/WeatherReport';
import { RefreshPortal } from '../general/Layout';
import RefreshButton from '../general/RefreshButton';
import ForecastCharts from './reports/forecast_report/ForecastCharts';
import WellsReportChartContainer from './reports/wells_report/WellsReport';
import { useIntl } from 'react-intl';
import { getIcon } from './markers';

function Admin(props) {

    const [stations, setStations] = useState({
        all: [],
        hasLowBattery: [],
        isLagging: [],
        isNormal: []
    });
    const [percentOfOrgsSensorsWithoutData, setPercentOfOrgsSensorsWithoutData] = useState(0)

    const [graphs, setGraphs] = useState({
        all: [],
        rRange: [],
        yRange: [],
        gRange: [],
        bRange: []
    });

    const [users, setUsers] = useState({
        all: [],
        today: [],
        thisMonth: [],
        inactive: []
    });
    const [insideMap, setInsideMap] = useState(window.screen.width > 700);
    const [stationGridData, setStationGridData] = useState([]);
    const [graphGridData, setGraphGridData] = useState([]);
    const [userGridData, setUserGridData] = useState([]);
    const [maps, setMaps] = useState([]);

    const [isAdmin, setIsAdmin] = useState(false);
    const [isOrganization, setIsOrganization] = useState(false);
    const [toDelete, setToDelete] = useState(undefined);
    const [deleting, setDeleting] = useState(false);
    const [isWorking, setIsworking] = useState({
        loadStations: false,
        loadGraphs: false,
        loadUsers: false
    });
    // prevent reload animation on first access
    const [notIntial, setNotInitial] = useState(false);
    const [loading, setLoading] = useState(false);
    const [forecastCharts, setForecastCharts] = useState([]);
    const [wellsCharts, SetWellsCharts] = useState([]);
    const [filter, setFilter] = useState('All');
    const intl = useIntl();
    const [markers, setMarkers] = useState([]);
    const [refreshAll, setRefreshAll] = useState(false);

    // Initialize markers from props.markers
    useEffect(() => {
        // setMarkers(props.markers)

        if (props.markers.length < 1)
            return

        // Determine markers colors & types
        setMarkers(props.markers.map(item => {
            const [icon, type] = getIcon(item)

            return {
                icon: icon,
                type: type,
                ...item
            }
        }))

    }, [props.markers])


    useEffect(() => {
        if ((isWorking.loadGraphs || isWorking.loadStations || isWorking.loadUsers) && notIntial) {
            setLoading(true);
        }
        else { setLoading(false); }
    }, [isWorking]);


    //refs for scrolling to grids
    const stationsRef = useRef(null);
    const graphsRef = useRef(null);
    const usersRef = useRef(null);
    const adminPageTopRef = useRef(null);
    const tabsRef = useRef(null);

    const loadStations = () => {
        setIsworking({ ...isWorking, loadStations: true });
        let url = BASEURI + '/api/dashboard/station/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((stations) => {
                        !notIntial && setNotInitial(true);
                        if (stations) {
                            setIsworking({ ...isWorking, loadStations: false })
                            setStations({
                                all: stations,
                                hasLowBattery: stations.filter(station => station.is_battery_low && !station.is_lagging),
                                isLagging: stations.filter(station => station.is_lagging),
                                isNormal: stations.filter(station => !station.is_battery_low && !station.is_lagging)
                            });
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }

    const loadGraphs = () => {
        setIsworking({ ...isWorking, loadGraphs: true });
        let url = BASEURI + '/api/dashboard/graph/paw/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((graphs) => {
                        setIsworking({ ...isWorking, loadGraphs: false });
                        if (Array.isArray(graphs)) {

                            let rRange = graphs.filter(graph => {
                                try {
                                    let v = graph.last_value[1];
                                    return v <= 30;
                                } catch {
                                    return true;
                                }

                            })

                            let yRange = graphs.filter(graph => {
                                try {
                                    let v = graph.last_value[1];
                                    return (30 < v) && (v <= 70);
                                } catch {
                                    return false;
                                }

                            });

                            let gRange = graphs.filter(graph => {
                                try {
                                    let v = graph.last_value[1];
                                    return (70 < v) && (v <= 100);
                                } catch {
                                    return false;
                                }

                            });

                            let bRange = graphs.filter(graph => {
                                try {
                                    let v = graph.last_value[1];
                                    return v > 100;
                                } catch {
                                    return false;
                                }

                            });

                            setGraphs({
                                all: graphs,
                                rRange: rRange,
                                yRange: yRange,
                                gRange: gRange,
                                bRange: bRange
                            });

                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            });

    }

    const loadUsers = () => {
        setIsworking({ ...isWorking, loadUsers: true });

        let url = BASEURI + '/api/dashboard/user/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((allUsers) => {
                        setIsworking({ ...isWorking, loadUsers: false });

                        //multiplier to convert miliseconds to hours
                        // divide by 1000 * 60 * 60 * 24
                        let divider = 1000 * 60 * 60 * 24;

                        let today = allUsers.filter(user => {
                            try {
                                let diffMS = Math.abs(new Date() - new Date(user.user.last_login));
                                return Math.round(diffMS / divider) <= 1;
                            } catch (error) {
                                return false;
                            }

                        }),
                            thisMonth = allUsers.filter(user => {
                                try {
                                    let diffMS = Math.abs(new Date() - new Date(user.user.last_login));
                                    return Math.round(diffMS / divider) <= 30;
                                }
                                catch (error) {
                                    return false;
                                }
                            }),
                            inactive = allUsers.filter(user => {
                                try {
                                    let diffMS = Math.abs(new Date() - new Date(user.user.last_login));
                                    return Math.round(diffMS / divider) > 30;
                                } catch (error) {
                                    return true;
                                }

                            });

                        setUsers({
                            all: allUsers,
                            today: today,
                            thisMonth: thisMonth,
                            inactive: inactive,
                        });
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }

    const loadForecastCharts = () => {

        let url = BASEURI + '/api/meteoblue/charts/forecast_charts/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    setLoading(false);
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((charts) => {
                        setForecastCharts(charts);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });

    }
    const loadWellsCharts = () => {
        let url = BASEURI + '/api/smart-wells/charts/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    setLoading(false);
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((charts_list) => {
                        SetWellsCharts(charts_list);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }

    const getOrgStatistics = () => {
        let url = BASEURI + '/api/dashboard/org-statistics/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    setLoading(false);
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })

                    .then((statistics) => {
                        console.log('the statistics: ', statistics)
                        setPercentOfOrgsSensorsWithoutData(
                            statistics?.percent_of_sensors_without_data
                        )
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }
    const refresh = () => {
        setRefreshAll(!refreshAll);
        loadStations();
        loadGraphs();
        loadUsers();
        loadForecastCharts();
        loadWellsCharts();
        getOrgStatistics();
    }

    useEffect(() => loadStations(), []);

    useEffect(() => getOrgStatistics(), []);

    useEffect(() => loadGraphs(), []);

    useEffect(() => loadUsers(), []);

    useEffect(() => {
        loadForecastCharts();
    }, []);

    useEffect(() => loadWellsCharts(), []);

    useEffect(() => {
        // document.getElementById('refresh').addEventListener('click', refresh);
        // upgrade mdl tabs
        if (window.componentHandler)
            window.componentHandler.upgradeElement(tabsRef.current);
        // return () => document.getElementById('refresh').removeEventListener('click', refresh);
    }, []);

    useEffect(() => {
        // check if user is superuser/admin
        let url = BASEURI + '/api/dashboard/superuser/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    props.logout();
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => response.json())
                    .then((client) => {

                        setIsAdmin(client.user.is_superuser);
                        setIsOrganization(client.is_organization);

                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });


    }, []);

    //load maps
    useEffect(() => {
        let url = BASEURI + '/api/dashboard/map/primary/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((map) => {
                        if (map)
                            setMaps([map]);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }, [])

    //effect to update station grid when an item is deleted
    useEffect(() => {
        setStationGridData(stations.all);
    }, [stations])


    function enterStationEdit(dataItem) {
        setStationGridData(stationGridData.map(station =>
            station.serial === dataItem.serial ?
                { ...station, inEdit: true } : station
        )
        )
    }

    function updateStation(dataItem) {

        const data = stationGridData.map(station =>
            station.serial === dataItem.serial ?
                { ...station, inEdit: undefined } : station
        );

        setStationGridData(data);

        let url = BASEURI + `/api/stations/${dataItem.serial}/`,
            options = {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(dataItem)
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;


                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((stationList) => {

                        //update original/base grid data
                        try {
                            setStations({
                                all: stations.all.map(station =>
                                    station.serial === dataItem.serial ?
                                        { ...dataItem, inEdit: undefined } : station
                                ),
                                hasLowBattery: stations.hasLowBattery.map(station =>
                                    station.serial === dataItem.serial ?
                                        { ...dataItem, inEdit: undefined } : station
                                ),
                                isLagging: stations.isLagging.map(station =>
                                    station.serial === dataItem.serial ?
                                        { ...dataItem, inEdit: undefined } : station
                                ),
                                isNormal: stations.isNormal.map(station =>
                                    station.serial === dataItem.serial ?
                                        { ...dataItem, inEdit: undefined } : station
                                )
                            });
                        } catch (error) {
                            console.log(error);
                        }

                    })
                    .catch(error => {
                        console.error('Error:', error.code);

                        //reset current grid data back to original item
                        // const originalStationItem = stations.all.find(station => station.serial === dataItem.serial);
                        // const data = stationGridData.map(station => station.serial === dataItem.serial ? originalStationItem : station);
                        // setStationGridData(data);
                        cancelStationEdit(dataItem);

                    });
            });
    }

    function cancelStationEdit(dataItem) {
        const originalStationItem = stations.all.find(station => station.serial === dataItem.serial);
        const data = stationGridData.map(station => station.serial === dataItem.serial ? originalStationItem : station);
        setStationGridData(data);
    }

    function onStationChange(event) {
        const data = stationGridData.map(station =>
            station.serial === event.dataItem.serial ?
                { ...station, [event.field]: event.value } : station
        );
        setStationGridData(data);
    }

    function enterDelete(dataItem) {
        setToDelete(dataItem);
    }

    function deleteStation() {
        setDeleting(true);
        let url = BASEURI + `/api/stations/${toDelete.serial}/`,
            options = {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    // this.setState({loggedin:false});
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then(() => {
                        setToDelete(undefined);
                        setDeleting(false);
                        loadStations();
                    })
                    .catch(error => {
                        setDeleting(false);
                        console.error('Error:', error.code);
                    });
            });

    }


    function setStationGrid(e, set) {
        if (set === 'Normal')
            setStationGridData(stations.isNormal);
        else if (set === 'Lagging')
            setStationGridData(stations.isLagging);
        else if (set === 'Low Battery')
            setStationGridData(stations.hasLowBattery);
        else
            setStationGridData(stations.all);
        executeRef(stationsRef);
        e.preventDefault();
    }

    function setGraphGrid(e, set) {

        // console.log(set);
        if (set === 'Stress severo')
            setGraphGridData(graphs.rRange);
        else if (set === 'Inicia stress')
            setGraphGridData(graphs.yRange);
        else if (set === 'Adecuado')
            setGraphGridData(graphs.gRange);
        else if (set === 'Exceso de agua')
            setGraphGridData(graphs.bRange);
        else
            setGraphGridData(graphs.all);
    }

    function executeRef(ref) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    const stationPieBox = useMemo(() => <PieBox key='stations' cols={3} setGridData={setStationGrid}
        isGraphPieBox={false}
        data={
            [{
                name: intl.formatMessage({ id: 'app.admin.stationPieBox.normal', defaultMessage: 'Normal' }),
                y: stations.isNormal.length,
                color: 'green'
            },
            {
                name: intl.formatMessage({ id: 'app.admin.stationPieBox.lagging', defaultMessage: 'Lagging' }),
                y: stations.isLagging.length,
                color: 'orange'
            },
            {
                name: intl.formatMessage({ id: 'app.admin.stationPieBox.lowBattery', defaultMessage: 'Low Battery' }),
                y: stations.hasLowBattery.length,
                color: 'red'
            }]
        }
        name={intl.formatMessage({ id: 'app.admin.stationPieBox.name', defaultMessage: 'Stations' })}
        all={stations.all.length}
    />, [stations, intl]);

    const graphPieBox = useMemo(() => <PieBox key='paw graphs' cols={3} setGridData={setGraphGrid}
        isGraphPieBox={true}
        setFilter={setFilter}
        insideMap={insideMap}
        data={[
            {
                id: "Stress severo",
                name: intl.formatMessage({ id: 'app.admin.map.severeStress', defaultMessage: 'Severe stress' }),
                y: markers.filter(item => item.type === 'RED').length,
                color: '#EF6666'
            },
            {
                id: "Inicia stress",
                name: intl.formatMessage({ id: 'app.admin.map.startStress', defaultMessage: 'Start stress' }),
                y: markers.filter(item => item.type === 'YELLOW').length,
                color: '#EFEF66'

            },
            {
                id: "Adecuado",
                name: intl.formatMessage({ id: 'app.admin.map.optimal', defaultMessage: 'Optimal' }),
                y: markers.filter(item => item.type === 'GREEN').length,
                color: '#5DAE5D'
            },
            {
                id: "Exceso de agua",
                name: intl.formatMessage({ id: 'app.admin.map.excessOfWater', defaultMessage: 'Excess of water' }),
                y: markers.filter(item => item.type === 'BLUE').length,
                color: '#00BFFF'
            }
        ]}
        name={intl.formatMessage({ id: 'app.admin.graphPieBox.name', defaultMessage: 'Paw Graphs' })}
    />, [markers, intl])

    const userPieBox = useMemo(() => <PieBox key='users' type='info' cols={3}
        isGraphPieBox={false}
        data={[
            {
                name: intl.formatMessage({ id: 'app.admin.userPieBox.today', defaultMessage: 'Today' }),
                y: users.today.length,
                color: 'green'
            },
            {
                name: intl.formatMessage({ id: 'app.admin.userPieBox.thisMonth', defaultMessage: 'This Month' }),
                y: users.thisMonth.length,
                color: 'blue'
            },
            {
                name: intl.formatMessage({ id: 'app.admin.userPieBox.inactive', defaultMessage: 'Inactive' }),
                y: users.inactive.length,
                color: 'red'
            }
        ]}
        name={intl.formatMessage({ id: 'app.admin.userPieBox.name', defaultMessage: 'Users' })}
        all={users.all.length}
    />, [users, intl])

    return (
        <div ref={adminPageTopRef} className='mdl-grid'>
            <RefreshPortal>
                <RefreshButton onClick={refresh} loading={loading} />
            </RefreshPortal>
            <div className='breadcrumb reports_page-breadcrumb mdl-grid mdl-cell mdl-cell--12-col'>
                {intl.formatMessage({ id: 'app.admin.reports', defaultMessage: 'REPORTS' })}
            </div>
            <div className='mdl-cell mdl-cell--12-col'>
                <GotoTopFAB adminPageTopRef={adminPageTopRef} executeRef={executeRef} />
                {/* <PiesContainer>
                    {stationPieBox}  
                    {graphPieBox}
                    {userPieBox}
                </PiesContainer>
                <MapContainer>
                    {maps.map( map => <GMap key={map.id} map={map} stations={stations.all}/>)}
                </MapContainer> */}


                {/* </div>
            <div> */}
                <div ref={tabsRef} className="mdl-tabs mdl-js-tabs mdl-js-ripple-effect">
                    <div className="mdl-tabs__tab-bar">
                        <a href="#paw-panel" className="mdl-tabs__tab is-active">{intl.formatMessage({ id: 'app.admin.reports.plant', defaultMessage: 'REPORTS' })}</a>
                        <a href="#stations-panel" className="mdl-tabs__tab">
                            {(isAdmin || isOrganization) ?
                                intl.formatMessage({ id: 'app.admin.reports.station_health_users', defaultMessage: "STATION HEALTH / USERS" }) :
                                intl.formatMessage({ id: 'app.admin.reports.station_health', defaultMessage: 'STATION HEALTH' })}
                        </a>
                        <a href="#weather-report" className="mdl-tabs__tab">
                            {intl.formatMessage({ id: 'app.admin.reports.weather', defaultMessage: 'WEATHER' })}
                        </a>
                        {/* <a href="#copy-panel" className="mdl-tabs__tab">
                            {(isAdmin || isOrganization) && 'Copy Charts / Dashboards'}
                        </a> */}
                        <a href="#forecast" className="mdl-tabs__tab">
                            {intl.formatMessage({ id: 'app.admin.reports.forecast', defaultMessage: 'FORECAST' })}
                        </a>
                        <a href="#wells-report" className="mdl-tabs__tab">
                            {intl.formatMessage({ id: 'app.admin.reports.wells', defaultMessage: 'WELLS' })}
                        </a>
                    </div>
                    <div className="mdl-tabs__panel is-active" id="paw-panel">
                        <PiesContainer>
                            {insideMap == false && graphPieBox}
                            {maps.map(map => <GMap
                                filter={filter}
                                graphPieBox={graphPieBox}
                                insideMap={insideMap}
                                key={map.id}
                                map={map}
                                isAdmin={isAdmin}
                                stations={stations.all}
                                markers={markers}
                            />)
                            }
                        </PiesContainer>
                        <GridContainer>
                            <GraphGrid
                                graphsRef={graphsRef}
                                data={graphGridData}
                                setData={setGraphGrid}
                            />
                        </GridContainer>
                    </div>
                    <div className="mdl-tabs__panel" id="stations-panel">
                        <PiesContainer>
                            {stationPieBox}
                            <StationGrid
                                stationsRef={stationsRef}
                                data={stationGridData}
                                setData={setStationGrid}
                                edit={enterStationEdit}
                                cancel={cancelStationEdit}
                                update={updateStation}
                                onChange={onStationChange}
                                remove={enterDelete}
                                percentOfOrgsSensorsWithoutData={percentOfOrgsSensorsWithoutData}
                            />
                        </PiesContainer>
                        <GridContainer>
                            {(isAdmin || isOrganization) && userPieBox}
                            {(isAdmin || isOrganization) &&
                                <UserGrid
                                    isAdmin={isAdmin}
                                    isOrganization={isOrganization}
                                    data={users}
                                    usersRef={usersRef}
                                />
                            }
                        </GridContainer>
                        {toDelete !== undefined &&
                            <ConfirmDeleteDialog
                                onClose={() => setToDelete(undefined)}
                                remove={deleteStation}
                                deleting={deleting}
                                message={<p> <b>{toDelete.name} [{toDelete.serial}] </b>{intl.formatMessage({ id: 'app.chart.deleteChart.deleteMessage', defaultMessage: "will be deleted along with all of it's data. Type {delete} below to confirm." }, { delete: <span style={{ color: 'red' }}>DELETE</span> })}</p>}
                            />
                        }
                    </div>
                    <div className="mdl-tabs__panel" id="weather-report">
                        <WeatherReport
                            setRefreshAll={setRefreshAll}
                            refreshAll={refreshAll}
                            stations={stations.all}
                        />
                    </div>
                    {/* <div className="mdl-tabs__panel" id="copy-panel">
                        {(isAdmin || isOrganization) && 
                        <React.Fragment>
                            <CopyChart  users= {users.all} />
                            <CopyDashboard users={users.all} />
                        </React.Fragment>
                        }
                    </div> */}
                    <div className="mdl-tabs__panel" id="forecast">
                        <ForecastCharts
                            charts={forecastCharts}
                        />
                    </div>
                    <div className="mdl-tabs__panel" id="wells-report">
                        <WellsReportChartContainer
                            charts={wellsCharts}
                        />
                    </div>

                </div>
            </div>
        </div>
    );
}


export default Admin;


function GotoTopFAB(props) {
    return (
        <div onClick={() => props.executeRef(props.adminPageTopRef)} className='ag-add-widget-button'>
            <button className="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored mdl-shadow--4dp mdl-color--accent">
                <i className="material-icons">arrow_upward</i>
            </button>
        </div>
    )
}