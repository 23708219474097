import { React } from 'react';
import { useIntl } from 'react-intl';


function EventItem(props) {
    const intl = useIntl();

    return <tr>
        <td className="bg-white p-0">
            <div className="relative">
                <div className={`absolute top-4 h-20 border-r-2 rounded-r-2xl ${props.color}`}></div>
                <div className="ml-6 border-b border-b-gray-100 pb-4">
                    <div className="mt-4 text-lg font-medium">{props.status}</div>
                    <div className="mt-2 text-base text-gray-400 font-medium">{intl.formatMessage({ id: 'app.scheduler.events.device', defaultMessage: 'Device: {name}' }, { name: props.deviceName })}</div>
                    <div className="text-base text-gray-400 font-medium">{intl.formatMessage({ id: 'app.scheduler.events.sector', defaultMessage: 'Sector: {name}' }, { name: props.sectorName })}</div>
                    <div className="text-gray-400">
                        <span className='inline-flex items-center text-sm'>
                            <i className="material-icons text-green-500 p-2 pl-0" >schedule</i>
                            <span>{intl.formatDate(props.created, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</span></span>
                    </div>
                </div>
            </div>
        </td>
    </tr>

}

export default EventItem;