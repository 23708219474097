import React, { Component } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import RawSensorForm from './RawSensorForm';
import PAWForm from './PAWForm';
import CustomFormulaForm from './CustomFormulaForm';
import EToForm from './EToForm';
import ChillPortionsForm from './ChillPortionsForm';
import ChillHoursFrom from './ChillHoursForm';
import DewPointForm from './DewPointForm';
import SatExECForm from './SatExECForm';
import ForecastFrom from './ForecastForm';
import CherryGrowingModelForm from './CherryGrowingModelForm';
import { getAuthToken, BASEURI } from '../utils';
import ETcForm from './ETcForm';
import VPDForm from './VPDForm';
import loading from '../loading.svg';
// import { TimeSelector } from '@progress/kendo-react-dateinputs/dist/npm/timepicker/TimeSelector';


class GraphChip extends Component {
	// constructor (props) {
	// 	super(props);
	// 	this.state = {
	// 		selected: true
	// 	}
	// }

	handleChipClick = (e) => {
		if (this.props.calculation !== 'Forecast' & !this.props.isFromWells) {
			this.props.handleStationChange(e, this.props.sensor.device)
				.then(() => {
					this.props.selectGraph(this.props.graphId);
				});
		}
		else {
			this.props.selectGraph(this.props.graphId);
		}

	}
	render() {

		// log(this.props);

		let type;
		if (this.props.type === 'column') {
			type = 'bar_chart';
		} else {
			type = 'show_chart';
		}

		if (this.props.failed)
			type = 'failed'

		return (
			<li className='ag-graphchip-item' title="Edit graph">
				<span className="mdl-chip mdl-chip--contact mdl-chip--deletable"
					style={this.props.selected ? { border: '1px solid #1CE4B1' } : { border: '1px solid white' }}
					onClick={this.handleChipClick}
				>
					<span className="mdl-chip__contact" style={{ background: this.props.color, color: 'white' }}>
						{
							this.props.loading ?
								<div className="lds-css ng-scope">
									<div className="lds-bars tiny">
										<div></div>
										<div></div>
										<div></div>
										<div></div>
									</div>
								</div>
								:

								<i className=" material-icons" >{type}</i>
						}

					</span>
					<span className="mdl-chip__text">
						{this.props.label}
						<span style={{ fontSize: '.9em' }}>
							&nbsp;{this.props?.sensor ? `[${this.props?.sensor?.device?.name} ${this.props?.sensor?.port}]` : ''}
						</span>
					</span>
					<a href="#dummy" onClick={() => this.props.remove(this.props.graphId)} className="mdl-chip__action">
						<i className="material-icons">cancel</i>
					</a>
				</span>
			</li>
		);
	}
}

class GraphChipContainer extends Component {
	render() {
		return (
			<ul className='ag-graphchip-container'>
				{this.props.children}
			</ul>
		);
	}
}

class ModalForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			allowSubmit: true,
			extracts: [],
			extractsCopy: [],
			previousStation: undefined,
			loading: false,
			message: '',
			name: '',
			calculation: 'Raw',
			editing: false,
			editingGraph: null,
			graphs: [],
			selected: null,
			current: {
				label: '',
				totalizer_initial_value: 0,
				_type: 'line',
				location_marker: null,
				weather_variable: '',
				sensors: [],
				extract: [],
				variables: {
					custom_formula_vars: {},
					paw_variables: {},
					plant: {
						id: null,
						initial_value: null,
						mid_value: null,
						late_value: null,
					},
					plantdate: null,
					treeshadow: null,
					fc: null,
					wp: null,
					formula: 'x',
					reset_on: '',
					threshold: '',
					latitude: null,
					altitude: null,
					temp: {
						sensor: [],
						extract: null
					},
					humidity: {
						sensor: [],
						extract: null
					},
					radiation: {
						sensor: [],
						extract: null
					},
					wind: {
						sensor: [],
						extract: null
					},
					ec: {
						sensor: [],
						extract: null
					},
					vwc: {
						sensor: [],
						extract: null
					},
					permitivity: {
						sensor: [],
						extract: null
					}
				},
				color: '#000000'
			},
			preCalculatedEto: true,
		};
		this.emptyGraph = {
			location_marker: null,
			weather_variable: '',
			sensors: [],
			calculation: null,
			label: '',
			_type: 'line',
			extract: [],
			y_axis_legend: '',
			axis_min: null,
			axis_max: null,
			variables: {
				custom_formula_vars: {},
				paw_variables: {},
				plant: {
					id: null,
					initial_value: null,
					mid_value: null,
					late_value: null,
				},
				plantdate: null,
				treeshadow: null,
				fc: null,
				wp: null,
				formula: 'x',
				reset_on: '',
				threshold: '',
				latitude: null,
				altitude: null,
				temp: {
					sensor: [],
					extract: null
				},
				humidity: {
					sensor: [],
					extract: null
				},
				radiation: {
					sensor: [],
					extract: null
				},
				wind: {
					sensor: [],
					extract: null
				},
				ec: {
					sensor: [],
					extract: null
				},
				vwc: {
					sensor: [],
					extract: null
				},
				permitivity: {
					sensor: [],
					extract: null
				}
			},
			color: '#000000'
		}
		this.sensorSelectElementNames = [
			'sensors',
			'temp_sensor',
			'humidity_sensor',
			'wind_sensor',
			'radiation_sensor',
			'ec_sensor',
			'vwc_sensor',
			'permitivity_sensor'
		];
		this.variables = [
			'fc',
			'wp',
			'formula',
			'reset_on',
			'latitude',
			'altitude',
			'threshold',
			'offset',
			'saturation',
			'rock_percent',
			'depth',
			'irrigated_soil_percent',
			'treeshadow'

		];
		this.newGraph = null;
		this.switchForm = this.switchForm.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.selectGraph = this.selectGraph.bind(this);
		this.addNewGraph = this.addNewGraph.bind(this);
		this.getMarker = this.getMarker.bind(this);
		this.handleStationFilterChange = this.handleStationFilterChange.bind(this);
		this.setPrecalculatedEto = this.setPrecalculatedEto.bind(this);
		this.handlePlantValueChange = this.handlePlantValueChange.bind(this);
		this.setPlantDays = this.setPlantDays.bind(this);
		this.setPlantValues = this.setPlantValues.bind(this);
		this.prescribeIrrigation = this.prescribeIrrigation.bind(this);
		this.removePrescription = this.removePrescription.bind(this);
		this.handleExtractFilterChange = this.handleExtractFilterChange.bind(this);
		this.handleAllowSubmit = this.handleAllowSubmit.bind(this);
		this.addLabel = this.addLabel.bind(this);
		// this.normalizeEToGraphVariables = this.normalizeEToGraphVariables.bind(this);
	}


	componentDidUpdate(_, prevState) {

		if (this.state.previousStation !== this.props.station) {
			try {
				this.setState({
					previousStation: this.props.station,
					current: {
						...this.state.current, variables: {
							...this.state.current.variables,
							latitude: this.props.station.details.location.lat,
							altitude: this.props.station.details.location.alt
						}
					}
				})
			} catch {
				this.setState({
					previousStation: this.props.station,
					current: {
						...this.state.current, variables: {
							...this.state.current.variables,
							latitude: null,
							altitude: null
						}
					}
				})
			}
		}

		// if (!prevState.current || !prevState.current.id)
		// return

		// // Find current graph in newly received list of graphs
		// let changedGraph;
		// for (let i in this.props.graphs)
		// if (prevState.current.id === this.props.graphs[i].id){
		// 	changedGraph = this.props.graphs[i];
		// 	break;
		// }

		// if (!changedGraph)
		// return

		// // If any of the variables changed update state.current with
		// // the newly received graph.
		// if (JSON.stringify(changedGraph.variables) !== JSON.stringify(prevState.current.variables))
		// this.setState({current: changedGraph})

	}


	handleAllowSubmit(current) {
		const calculationsWithResetDate = ['Chill Portions', 'Degree Days', 'Chill Hours', 'Cherry Growing Model']

		let sensorsIDs = current.sensors.map(item => item.id);
		let extractSensorsIDs = current.extract.map(item => item.sensor.id);

		if (current.calculation === 'ETo' || (current.calculation === "ETc" && !current.variables.preCalculatedEto)) {
			if (current.variables.temp.sensor.length < 1 || !current.variables.temp.extract.hasOwnProperty('id') ||
				current.variables.humidity.sensor.length < 1 || !current.variables.humidity.extract.hasOwnProperty('id') ||
				current.variables.radiation.sensor.length < 1 || !current.variables.radiation.extract.hasOwnProperty('id') ||
				current.variables.wind.sensor.length < 1 || !current.variables.wind.extract.hasOwnProperty('id'))
				return false

		} else if (current.calculation === 'Dew Point') {

			if (current.variables.temp.sensor.length < 1 || !current.variables.temp.extract.hasOwnProperty('id') ||
				current.variables.humidity.sensor.length < 1 || !current.variables.humidity.extract.hasOwnProperty('id'))
				return false

		} else if (current.calculation === 'VPD') {

			if (current.variables.temp.sensor.length < 1 || !current.variables.temp.extract.hasOwnProperty('id') ||
				current.variables.humidity.sensor.length < 1 || !current.variables.humidity.extract.hasOwnProperty('id'))
				return false
		} else if (current.calculation === 'Saturation ex EC') {

			if (current.variables.temp.sensor.length < 1 || !current.variables.temp.extract.hasOwnProperty('id') ||
				current.variables.ec.sensor.length < 1 || !current.variables.ec.extract.hasOwnProperty('id') ||
				current.variables.vwc.sensor.length < 1 || !current.variables.vwc.extract.hasOwnProperty('id') ||
				current.variables.permitivity.sensor.length < 1 || !current.variables.permitivity.extract.hasOwnProperty('id') ||
				!current.variables.offset || !current.variables.saturation
			)
				return false

		} else if (current.calculation === 'Forecast' & !current.location_marker & !current.weather_variable) {
			return false

		} else if ([
			'Raw',
			'PAW',
			'Chill Portions',
			'Degree Days',
			'Chill Hours',
			'Cherry Growing Model',
			'Custom Formula',
		].indexOf(current.calculation) !== -1) {
			if (sensorsIDs.length < 1)
				return false
			if (!sensorsIDs.every(r => extractSensorsIDs.includes(r))) {
				return false
			}
		}

		if (!current.label)
			return false



		if (calculationsWithResetDate.indexOf(current.calculation) !== -1 & !current.variables.reset_on)
			return false


		if (['Degree Days', 'Chill Hours'].indexOf(current.calculation) !== -1 & !current.variables.threshold)
			return false


		return true
	}

	addLabel(current) {
		// Add sensor name as Label if user does not provided one
		if (!current.label) {
			if (current.sensors.length > 0) {
				const label = current.sensors[0]?.name
				current.label = label
			}
		}
	}

	calculations = [
		'Raw',
		'PAW',
		'Chill Portions',
		'Degree Days',
		'Chill Hours',
		'Cherry Growing Model',
		'ETo',
		'ETc',
		'Dew Point',
		'VPD',
		'Saturation ex EC',
		'Custom Formula',
		'Forecast'
	]

	removePrescription() {
		this.setState({ loading: true });
		let url = `${BASEURI}/api/irrigation/prescribe-irrigation/`,
			options = {
				method: 'DELETE',
				body: JSON.stringify(
					{
						chart: this.props.chartId,
					}
				),
				headers: {
					"Content-Type": "application/json; charset=utf-8",
				}
			};
		getAuthToken()
			.then(token => token)
			.catch(token => token)
			.then(token => {

				//no token exists or all tokens are expired
				if (token === false) {
					return false;

				}

				options.headers.Authorization = `Bearer  ${token}`;

				fetch(url, options)
					.then((response) => {
						if (!response.ok) {
							response.text().then(err => console.log(err));
							throw new Error(response.statusText, response.status);
						}
						return response.json();
					})
					.then((response) => {
						this.setState({
							loading: false,
							message: response.message,
						});
					})
					.catch(error => {
						console.error('Error:', error);
					});
			});
	}

	prescribeIrrigation() {
		this.setState({ loading: true });

		let url = `${BASEURI}/api/irrigation/prescribe-irrigation/`,
			options = {
				method: 'POST',
				body: JSON.stringify(
					{
						chart: this.props.chartId,
					}
				),
				headers: {
					"Content-Type": "application/json; charset=utf-8",
				}
			};
		getAuthToken()
			.then(token => token)
			.catch(token => token)
			.then(token => {

				//no token exists or all tokens are expired
				if (token === false) {
					return false;

				}

				options.headers.Authorization = `Bearer  ${token}`;

				fetch(url, options)
					.then((response) => {
						if (!response.ok) {
							response.text().then(err => console.log(err));
							throw new Error(response.statusText, response.status);
						}
						return response.json();
					})
					.then((response) => {
						this.setState({
							loading: false,
							message: response.message,
						});
					})
					.catch(error => {
						console.error('Error:', error);
					});
			});
	}

	handleStationFilterChange(e) {
		let filtered = this.props.allStations.filter(item => {
			let filterText = e.filter.value.toLowerCase(),
				itemText = `${item.name.toLowerCase()} ${item.serial}`;

			for (let i in filterText.split(' ')) {
				let textSplit = filterText.split(' ')[i];

				if (itemText.indexOf(textSplit) !== -1)
					return true
				else
					return false
			}
			return true;
		});
		this.props.setStations(filtered);
	}


	getMarker(id) {
		for (let i in this.props.markers) {
			if (this.props.markers[i].id == id) {
				return this.props.markers[i];
			};
		};
	}

	switchForm(e) {
		this.setState({
			calculation: e.target.value
		});
	}

	editGraph = (event) => {
		this.setState({
			editing: true,
			editingGraph: event.target.id
		});
	}

	handleChange(event) {
		// console.log(event.target.value)
		let current = this.state.current;
		const multiWordFields = [
			'axis_min',
			'axis_max',
			'_type',
			'reset_on',
			'resample_fn',
			'y_axis_legend',
			'data_labels',
			'weather_variable',
			'location_marker',
			'paw_guides',
			'predict_fc',
			'rock_percent',
			'irrigated_soil_percent',
			'totalizer_initial_value'
		]
		const LETTERS = 'xyzabcdefghijklmnopqrstuvw'


		// Handle FC and WP change
		if (event.target.name.split('.')[1] === 'fc' || event.target.name.split('.')[1] === 'wp') {

			const target_name = event.target.name.split('.')

			current.variables.paw_variables[target_name[0]][target_name[1]] = isNaN(parseFloat(event.target.value)) ? '' : parseFloat(event.target.value);
		}

		if (event.target.name !== undefined) {
			if (event.target.name.split('_').length > 1 &&
				multiWordFields.indexOf(event.target.name) === -1) {
				let target = event.target.name.split('_');
				current.variables[target[0]][target[1]] = event.target.value;
			} else {
				if (this.variables.indexOf(event.target.name) !== -1) {
					//parse fc and wp value to float		
					if (event.target.name === 'fc' || event.target.name === 'wp') {
						current.variables[event.target.name] = isNaN(parseFloat(event.target.value)) ? '' : parseFloat(event.target.value);
						current.predict_fc = false;
					}
					else
						current.variables[event.target.name] = event.target.value;
				} else {
					if (['axis_min', 'axis_max'].indexOf(event.target.name) !== -1 && event.target.value === '')
						current[event.target.name] = null;
					else {
						current[event.target.name] = event.target.value;

						// Add FC and WP for the sensor in variable
						if (['paw', 'PAW'].indexOf(current.calculation) !== -1) {
							for (let sensor in current.sensors) {
								if (!current.variables.paw_variables.hasOwnProperty(current.sensors[sensor].id)) {
									current.variables.paw_variables = {
										...current.variables.paw_variables,
										[current.sensors[sensor].id]: {
											fc: '',
											wp: ''
										}
									}
								}
							}
						}
					}
				}

			}

		} else
			current[event.target.props.name] = event.target.value;

		// Add variable label for extract in graph variables
		if (event.target.name === 'extract') {
			current.variables.custom_formula_vars = {};
			for (let extract in event.target.value) {
				current.variables.custom_formula_vars = {
					...current.variables.custom_formula_vars,
					[event.target.value[extract].id]: LETTERS[extract]
				}
			}
		}

		// Add sensor extracts to extracts list
		let extracts = [];
		if (this.sensorSelectElementNames.indexOf(event.target.name) !== -1) {
			for (let i in event.target.value)
				extracts = extracts.concat(event.target.value[i].extract);


			// Remove selected extract which sensor is deselected
			current.extract = current.extract.filter(item => {
				for (let extract_index in extracts) {
					if (extracts[extract_index].id === item.id)
						return true
					else
						return false
				}
			})
		}

		if (['_type', 'resample', 'resample_fn'].indexOf(event.target.name) !== -1)
			current[event.target.name] = event.target.value.value;

		if (event.target.name === 'plant' || event.target.name === 'plantdate' || event.target.name === 'treeshadow') {

			current.variables = {
				...current.variables,
				[event.target.name]: event.target.value,
				preCalculatedEto: this.state.preCalculatedEto
			}

			this.setState({
				current: current,
			});

		} else {
			this.setState({
				current: current,
			});
		}

		if (extracts.length > 0) {
			this.setState({
				extracts: extracts,
				extractsCopy: extracts
			});
		}

	}

	handleExtractFilterChange(event) {
		const text = event.filter.value.toLowerCase();
		let extracts = this.state.extractsCopy.filter((extract) => {
			if (extract.description.toLowerCase().indexOf(text) !== -1) {
				return true
			}
		})
		this.setState({
			extracts: extracts
		})
	}

	selectGraph(graphId) {
		let graph;
		//find selected graph and set it as current
		for (let i in this.props.graphs) {
			if (this.props.graphs[i].id === graphId)
				graph = Object.assign({}, this.props.graphs[i]);
		}
		const markerobject = this.getMarker(graph.location_marker);
		graph.location_marker = markerobject;

		if (graph.calculation === 'ETo' || graph.calculation === 'Dew Point' ||
			graph.calculation === 'Saturation ex EC' || graph.calculation === 'VPD' || (graph.calculation === "ETc" && !graph.variables.preCalculatedEto)) {
			graph.variables = this.props.normalizeEToGraphVariables(graph.variables);
		}

		let extracts = [];
		if (!graph.variables['preCalculatedEto']) {
			this.setState({ preCalculatedEto: graph.variables['preCalculatedEto'] })
		} else {
			this.setState({ preCalculatedEto: true })
		}
		for (let i in graph.sensors)
			extracts = extracts.concat(graph.sensors[i].extract);

		this.setState(state => (
			{
				selected: state.selected === graphId ? null : graphId,
				current: graph,
				extracts: extracts,
				extractsCopy: extracts,
				calculation: graph.calculation,
			}
		)
		);

	}

	setPrecalculatedEto(e) {
		this.setState({ preCalculatedEto: !this.state.preCalculatedEto, current: { ...this.state.current, variables: { ...this.state.current.variables, preCalculatedEto: !this.state.preCalculatedEto } } });

	}

	addNewGraph(e) {
		if (this.props && this.props.sensors) {
			this.setState({ selected: null, current: JSON.parse(JSON.stringify(this.emptyGraph)) });
			this.props.handleStationChange(e, null);
		}
	}

	handlePlantValueChange(event) {
		let current = this.state.current;

		current.variables = {
			...current.variables,
			plant: {
				...this.state.current.variables.plant,
				[event.target.name]: event.target.value
			}
		}

		this.setState({
			current: current
		});
	}

	setPlantDays(periods) {
		this.setState({
			current: {
				...this.state.current,
				variables: {
					...this.state.current.variables,
					plant: {
						...this.state.current.variables.plant,
						initial_days: periods.initial,
						mid_days: periods.mid,
						dev_days: periods.dev,
						late_days: periods.late
					}
				}
			}
		});
	}

	setPlantValues(values) {
		this.setState({
			current: {
				...this.state.current,
				variables: {
					...this.state.current.variables,
					plant: {
						...this.state.current.variables.plant,
						initial_value: values.initial,
						mid_value: values.mid,
						late_value: values.late
					}
				}
			}
		});
	}

	render() {

		let errors = [];
		if (typeof this.props.errors === 'object') {
			for (let error in this.props.errors) {
				errors.push(<div key={error}>{error} : {this.props.errors[error]}</div>)
			}
		} else {
			errors = this.props.errors
		}

		let form, calculation = this.state.current.calculation ? this.state.current.calculation : 'Raw';

		switch (calculation) {
			case 'paw':
			case 'PAW':
				form = <PAWForm
					intl={this.props.intl}
					graph={this.state.current}
					sensors={this.props.sensors}
					handleChange={this.handleChange}
					extracts={this.state.extracts}
					handleFilterChange={this.props.handleFilterChange}
					noData={this.props.noData}
					loadingSensors={this.props.loadingSensors}
					handleExtractFilterChange={this.handleExtractFilterChange}
				/>;
				break;
			case 'chill_portions':
			case 'Chill Portions':
				form = <ChillPortionsForm
					intl={this.props.intl}
					graph={this.state.current}
					sensors={this.props.sensors}
					extracts={this.state.extracts}
					handleChange={this.handleChange}
					handleFilterChange={this.props.handleFilterChange}
					noData={this.props.noData}
					loadingSensors={this.props.loadingSensors}
					handleExtractFilterChange={this.handleExtractFilterChange}
				/>;
				break;
			case 'Chill Hours':
			case 'Degree Days':
				form = <ChillHoursFrom
					intl={this.props.intl}
					graph={this.state.current}
					sensors={this.props.sensors}
					extracts={this.state.extracts}
					handleChange={this.handleChange}
					handleFilterChange={this.props.handleFilterChange}
					noData={this.props.noData}
					loadingSensors={this.props.loadingSensors}
					handleExtractFilterChange={this.handleExtractFilterChange}
				/>;

				break;
			case 'Cherry Growing Model':
				form = <CherryGrowingModelForm
					intl={this.props.intl}
					graph={this.state.current}
					sensors={this.props.sensors}
					extracts={this.state.extracts}
					handleChange={this.handleChange}
					handleFilterChange={this.props.handleFilterChange}
					noData={this.props.noData}
					loadingSensors={this.props.loadingSensors}
					handleExtractFilterChange={this.handleExtractFilterChange}
				/>;
				break
			case 'ETo':
				form = <EToForm
					intl={this.props.intl}
					graph={this.state.current}
					sensors={this.props.sensors}
					extracts={this.state.extracts}
					handleChange={this.handleChange}
					handleFilterChange={this.props.handleFilterChange}
					noData={this.props.noData}
					loadingSensors={this.props.loadingSensors}
				/>;
				break;
			case "ETc":
				form = <ETcForm
					intl={this.props.intl}
					preCalculatedEto={this.state.preCalculatedEto}
					graph={this.state.current}
					sensors={this.props.sensors}
					extracts={this.state.extracts}
					handleChange={this.handleChange}
					handleFilterChange={this.props.handleFilterChange}
					noData={this.props.noData}
					setPrecalculatedEto={this.setPrecalculatedEto}
					loadingSensors={this.props.loadingSensors}
					setPlantDays={this.setPlantDays}
					setPlantValues={this.setPlantValues}
					handlePlantValueChange={this.handlePlantValueChange}
				/>
				break;
			case 'Dew Point':
				form = <DewPointForm
					intl={this.props.intl}
					graph={this.state.current}
					sensors={this.props.sensors}
					extracts={this.state.extracts}
					handleChange={this.handleChange}
					handleFilterChange={this.props.handleFilterChange}
					noData={this.props.noData}
					loadingSensors={this.props.loadingSensors}
				/>;
				break;
			case "VPD":
				form = <VPDForm
					intl={this.props.intl}
					graph={this.state.current}
					sensors={this.props.sensors}
					extracts={this.state.extracts}
					handleChange={this.handleChange}
					handleFilterChange={this.props.handleFilterChange}
					noData={this.props.noData}
					loadingSensors={this.props.loadingSensors}
				/>;
				break;
			case 'Saturation ex EC':
				form = <SatExECForm
					intl={this.props.intl}
					graph={this.state.current}
					sensors={this.props.sensors}
					extracts={this.state.extracts}
					handleChange={this.handleChange}
					handleFilterChange={this.props.handleFilterChange}
					noData={this.props.noData}
					loadingSensors={this.props.loadingSensors}
				/>;
				break;
			case 'custom':
			case 'Custom Formula':
				form = <CustomFormulaForm
					intl={this.props.intl}
					graph={this.state.current}
					sensors={this.props.sensors}
					extracts={this.state.extracts}
					handleChange={this.handleChange}
					handleFilterChange={this.props.handleFilterChange}
					noData={this.props.noData}
					loadingSensors={this.props.loadingSensors}
					handleExtractFilterChange={this.handleExtractFilterChange}
					isFromWells={this.props.isFromWells}
				/>;
				break;
			case 'Forecast':
			case 'forecast':
				form = <ForecastFrom
					intl={this.props.intl}
					graph={this.state.current}
					handleChange={this.handleChange}
					handleFilterChange={this.props.handleMarkersFilterChange}
					markers={this.props.markers}
					weatherVariables={this.props.weatherVariables}
					loadingWeatherVariables={this.props.loadingWeatherVariables}
					noData={this.props.noData}
					loadingMarkers={this.props.loadingMarkers}

				/>;
				break;
			default:
				form = <RawSensorForm
					intl={this.props.intl}
					graph={this.state.current}
					sensors={this.props.sensors}
					extracts={this.state.extracts}
					handleChange={this.handleChange}
					handleFilterChange={this.props.handleFilterChange}
					noData={this.props.noData}
					loadingSensors={this.props.loadingSensors}
					handleExtractFilterChange={this.handleExtractFilterChange}
				/>;
				break;
		}

		let graphs = this.props.graphs.map((graph) => {
			return (
				<GraphChip
					isFromWells={this.props.isFromWells}
					calculation={graph.calculation}
					stations={this.props.stations}
					sensors={this.props.sensors}
					type={graph._type}
					color={graph.color}
					label={graph.label}
					sensor={graph.sensors[0]}
					key={graph.id}
					graphId={graph.id}
					remove={this.props.removeGraph}
					loading={(graph.id === this.props.changing)}
					failed={false}
					selected={this.state.selected === graph.id}
					selectGraph={this.selectGraph}
					handleStationChange={this.props.handleStationChange}
				/>
			);
		});

		//sort graph chips based on sensor port number
		graphs.sort((a, b) => {
			// if graph has no sensor send it to the end of the 
			// sorted array: by returning 1 if 'a' doesn't have
			// a sensor indicating 'a' is bigger and -1 if
			//'b' doesn't have a sensor.
			if (!a.props.sensor) return 1;
			if (!b.props.sensor) return -1;

			const a_val = parseInt(a.props.sensor.port);
			const b_val = parseInt(b.props.sensor.port);

			// if sensor has no port number (has '-' instead) 
			// send it to the end of the sorted array: by returning 1 
			// if 'a' doesn't have a port number indicating 'a' is
			// bigger and -1 if 'b' doesn't have a port number.
			if (isNaN(a_val)) return 1;
			if (isNaN(b_val)) return -1;

			return (a_val < b_val) ? -1 : ((a_val > b_val) ? 1 : 0);
		});

		let chartTitle = this.props.chartTitle;
		let dialog = this.props.visible &&
			<Dialog title={this.props.intl.formatMessage({ id: 'app.chart.chartDialog.Editing', defaultMessage: 'Editing' }) + chartTitle} onClose={this.props.close} className='edit-chart-dialog'>

				<div className='modal-wrap'>
					<div className='modal-form-wrap'>
						<div className="modal-form-inputs-wrap-row">
							<DropDownList
								label={this.props.intl.formatMessage({ id: 'app.chart.chartDialog.calculation', defaultMessage: 'CALCULATION' })}
								name="calculation"
								onChange={this.props.isFromWells ? null : this.handleChange}
								data={this.calculations}
								value={this.props.isFromWells ? 'Custom Formula' : this.state.current.calculation}
							/>
							{

								!this.props.isFromWells && (this.state.current.calculation !== 'Forecast' ?
									<DropDownList
										filterable={true}
										onFilterChange={this.handleStationFilterChange}
										label={this.props.intl.formatMessage({ id: 'app.chart.chartDialog.station', defaultMessage: 'STATION' })}
										name="station"
										textField="name"
										onChange={this.props.handleStationChange}
										data={this.props.stations}
										value={this.props.station}
										onClose={() => this.props.setStations(this.props.allStations)}
									/>
									:
									<div></div>)
							}
						</div>
						{form}


						{!this.state.editing &&
							<button
								disabled={this.props.savingGraph}
								style={{ marginTop: '15px' }}
								className="mdl-button mdl-js-button mdl-button--primary"
								onClick={(e) => {
									let current = this.state.current

									this.addLabel(current)

									if (this.handleAllowSubmit(current)) {
										this.setState({ allowSubmit: true })
									} else {
										this.setState({ allowSubmit: false })
										return
									}

									this.props.addGraph(current);
									if (!this.state.selected)
										this.addNewGraph(e);
								}}
							>
								{
									this.props.savingGraph ? <img style={{ width: '20px', marginLeft: 'auto', marginRight: 'auto' }} src={loading} alt='loading' /> : !this.state.selected ?
										this.props.intl.formatMessage({ id: 'app.chart.chartDialog.addNew', defaultMessage: 'Add New' }) :
										this.props.intl.formatMessage({ id: 'app.chart.chartDialog.update', defaultMessage: 'Update' })}
							</button>
						}
						<div style={{ color: 'red' }}>
							{!this.state.allowSubmit && this.props.intl.formatMessage({ id: 'app.chart.chartDialog.validationError', defaultMessage: 'Please fill all required fields!' })}
							{<span style={{ color: 'red' }}>{errors}</span>}
						</div>

					</div>
					<div className='modal-calendar-wrap'>
						<GraphChipContainer>
							{graphs}

							<li className='ag-graphchip-item' title="Add new graph">
								<span className="mdl-chip mdl-chip--contact ag-graphchip-add"
									style={{
										background: this.state.selected ? 'white' : '#f0f0f0',
										border: !this.state.selected ? '1px solid #1CE4B1' : '1px solid white',
									}}
									onClick={this.addNewGraph}
								>
									<span className="mdl-chip__contact">
										<i className=" material-icons" style={{ color: 'black' }}>add</i>
									</span>
								</span>
							</li>
						</GraphChipContainer>
						<span style={{ color: 'red' }}>{this.state.message}</span>
					</div>
				</div>

				<DialogActionsBar>
					<button
						title={this.props.intl.formatMessage({ id: 'app.chart.chartDialog.removePrescription', defaultMessage: 'Remove Prescription' })}
						className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md"
						style={{ color: 'red' }}
						onClick={this.removePrescription}
					>
						<span className='text-ellipsis overflow-hidden'>
							{this.state.loading ? <img style={{ width: '20px' }} src={loading} alt='loading' /> :
								this.props.intl.formatMessage({ id: 'app.chart.chartDialog.removePrescription', defaultMessage: 'Remove Prescription' })}
						</span>
					</button>
					<button
						title={this.props.intl.formatMessage({ id: 'app.chart.chartDialog.prescribeIrrigation', defaultMessage: 'Prescribe Irrigation' })}
						className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md"
						style={{ color: 'green' }}
						onClick={this.prescribeIrrigation}
					>
						<span className='text-ellipsis overflow-hidden'>
							{this.state.loading ? <img style={{ width: '20px' }} src={loading} alt='loading' /> :
								this.props.intl.formatMessage({ id: 'app.chart.chartDialog.prescribeIrrigation', defaultMessage: 'Prescribe Irrigation' })}
						</span>
					</button>
					<button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={this.props.close}>
						{this.props.intl.formatMessage({ id: 'app.chart.chartDialog.done', defaultMessage: 'Done' })}
					</button>
				</DialogActionsBar>
			</Dialog>;
		return (dialog);
	}
}

export default ModalForm;
