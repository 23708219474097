import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { getAuthToken, BASEURI } from '../utils';
import { MultiSelect, DropDownList } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import Modal from '../general/Modal';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { RecurrenceEditor } from "./Editors";

const NEW_TASK = {
    device: null,
    sectors: [],
    start: new Date(),
    end: new Date(),
    duration: 0,
    recurrence_rule: '',
    precip: 0
}

function TaskForm(props) {
    const intl = useIntl();
    const [newTask, setNewTask] = useState(NEW_TASK);

    // useEffect(() => {
    //     setNewTask({
    //         ...newTask,
    //         sectors: props.sectors
    //     })
    // }, [props.sectors])

    function handleChange(e) {
        let name, value = '';
        if (e.name === 'recurrence_rule') {
            name = e.name;
            value = e.value;
        } else {
            name = e.target.name;
            value = e.target.value;
        }

        if (name === 'device') {
            props.setStation(value.serial);
            setNewTask({
                ...newTask,
                device: value
            })
        } else {
            setNewTask({
                ...newTask,
                [name]: value
            })
        }

    }

    function addTasks() {
        props.setAddingTask(false);
        props.onClose(false);
        let dataItem = newTask;
        dataItem.device = dataItem.device.serial
        dataItem.sectors = dataItem.sectors.map(item => item.port)

        let url = BASEURI + `/api/scheduler/tasks/add-group-tasks/`,
            options = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(dataItem)
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })

                    .then((messages) => {
                        setNewTask(NEW_TASK);
                        props.loadTasks();
                        console.log(messages);
                        props.setAddingTask(false);
                    })

                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });

    }
    function handleCloseButton() {
        props.onClose(false);
    }

    function itemRender(li, itemProps) {
        const itemChildren = <div> {li.props.children} <div style={{ color: "#00F" }}> {itemProps.dataItem.device.name} [{itemProps.dataItem.port}]</div> </div>;
        return React.cloneElement(li, li.props, itemChildren);
    }

    return (
        <div><Modal>
            <Dialog onClose={handleCloseButton} title={intl.formatMessage({ id: 'app.scheduler.taskModel.addIrrigation', defaultMessage: "Group Add Irrigation" })}>
                <div className='mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone'>
                    <div className='modal-form-inputs-wrap' >
                        <div className='modal-form-inputs-wrap-row' >
                            <DropDownList
                                name='device'
                                label={intl.formatMessage({ id: 'app.scheduler.taskModel.station', defaultMessage: 'Station' })}
                                dataItemKey='serial'
                                textField='name'
                                valueField='serial'
                                data={props.stations}
                                value={newTask.device}
                                onChange={handleChange}
                                required={true}
                                filterable={true}
                                onFilterChange={props.handleStationFilterChange}
                            />
                            <MultiSelect
                                name='sectors'
                                label={intl.formatMessage({ id: 'app.scheduler.taskModel.sectors', defaultMessage: 'Sectors' })}
                                data={props.sectors}
                                textField='name'
                                value={newTask.sectors}
                                onChange={handleChange}
                                required={true}
                                itemRender={itemRender}
                                tags={

                                    newTask.sectors.length > 0 ?

                                        newTask.sectors.length > 1 ?
                                            [
                                                {
                                                    text: `${newTask.sectors.length} ${intl.formatMessage({ id: 'app.admin', defaultMessage: 'Sectors selected' })}`,
                                                    data: [...props.sectors],
                                                },
                                            ] : [
                                                {
                                                    text: `${newTask.sectors[0]?.name}`,
                                                    data: [...props.sectors],
                                                },
                                            ]
                                        : []
                                }
                                style={{ height: 'auto' }}
                            />
                        </div>
                        <div className='modal-form-inputs-wrap-row' >
                            <DateTimePicker
                                name='start'
                                label={intl.formatMessage({ id: 'app.scheduler.taskModel.start', defaultMessage: 'Start' })}
                                onChange={handleChange}
                                value={newTask.start}
                                required={true}
                            />
                            <NumericTextBox
                                name='duration'
                                label={intl.formatMessage({ id: 'app.scheduler.taskModel.duration', defaultMessage: 'Duration (H)' })}
                                onChange={handleChange}
                                value={newTask.duration}
                                required={!newTask.precip}
                            />

                        </div>
                        <div className='modal-form-inputs-wrap-row' >
                            <NumericTextBox
                                name='precip'
                                label={intl.formatMessage({ id: 'app.scheduler.taskModel.precip', defaultMessage: 'Precipitation (mm)' })}
                                onChange={handleChange}
                                value={newTask.precip}
                            />
                        </div>
                        <div className='modal-form-inputs-wrap-row' >
                            <RecurrenceEditor
                                onChange={handleChange}
                                name='recurrence_rule'
                                value={newTask.recurrence_rule}
                            />
                        </div>
                    </div>
                </div>
                <DialogActionsBar>
                    <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={handleCloseButton}>{intl.formatMessage({ id: 'app.scheduler.taskModel.close', defaultMessage: 'Close' })}</button>
                    {/* <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={handleDeleteButton}>{intl.formatMessage({id:'app.scheduler.taskModal.delete', defaultMessage:'Delete'})}</button> */}
                    <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-primary" onClick={addTasks}>
                        {intl.formatMessage({ id: 'app.scheduler.taskModel.add', defaultMessage: 'Add' })}
                    </button>
                </DialogActionsBar>
            </Dialog>
        </Modal>
            {/* <button onClick={addTasks} className='mdl-button mdl-js-button mdl-button--primary'>Add Task</button> */}
        </div>)
}

export default TaskForm;