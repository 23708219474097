import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { BASEURI, getAuthToken } from '../utils';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input, NumericTextBox, Switch } from '@progress/kendo-react-inputs';
import Highcharts from 'highcharts';
import Modal from '../general/Modal';
import FilterMarkers from './FilterMarkers'
import forecast_station from '../images/forecast_station.png';
import loadingIcon from '../loading.svg';
import loadingWhite from '../loading_white.svg';
import farmLand from '../images/land.png';
import { getCoordinates } from './markers';




const markerTemplate = {
    location_name: '',
    paw_graphs: [],
    graphs: [],
    device: {
        details: {
            location: {
                lng: '',
                lat: ''
            }
        },
        users: []
    }
}
let markersObjects = [];

let markersLoaded = true; // useState is not synchronous and it was not working

function MapContainer(props) {

    return (
        <div className="mdl-grid mdl-grid--no-spacing station-grid--container" style={{ justifyContent: 'center' }}>
            <div className="mdl-cell mdl-cell--12-col">
                <div className='mdl-grid'>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

function SparkLine(props) {
    const chartContainer = useRef();
    const [oneWeekGraphData, setOneWeekGraphData] = useState({ data: [], date: [] });
    const [loading, setLoading] = useState(false);
    const [loggedin, setLoggedin] = useState();
    let pawBands = [
        {
            from: 1,
            to: 30,
            color: 'rgba(255,179,186,.3)'
        }, {
            from: 30,
            to: 70,
            color: 'rgba(255,255,186,.3)'
        }, {
            from: 70,
            to: 100,
            color: 'rgba(186,255,201,.3)'
        }, {
            from: 100,
            to: 200,
            color: 'rgba(110,192,255,.3)'
        }
    ]


    const defaultOptions = {
        chart: {
            backgroundColor: null,
            borderWidth: 0,
            type: 'line',
            margin: [10, 0, 5, 0],
            height: 90,
            style: {
                overflow: 'visible'
            },
            skipClone: true
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false
        },
        xAxis: {
            labels: {
                enabled: false
            },
            title: {
                text: null
            },
            startOnTick: false,
            endOnTick: false,
            //   tickPositions: [],
            type: 'datetime',
            //   categories: oneWeekGraphData.date
        },
        yAxis: {
            plotBands: pawBands,
            min: 0,
            max: 180,
            endOnTick: false,
            startOnTick: false,
            labels: {
                enabled: false
            },
            title: {
                text: null
            },
            tickPositions: [0]
        },
        legend: {
            enabled: false
        },
        tooltip: {
            hideDelay: 0,
            outside: true,
            shared: true
        },
        plotOptions: {
            series: {
                animation: false,
                lineWidth: 1,
                shadow: false,
                states: {
                    hover: {
                        lineWidth: 1
                    }
                },
                marker: {
                    radius: 1,
                    states: {
                        hover: {
                            radius: 2
                        }
                    }
                },
                fillOpacity: 0.25
            },
            column: {
                negativeColor: '#910000',
                borderColor: 'silver'
            }
        },

        series: [{
            data: [1, 2, 3]
        }]
    };
    useEffect(() => {
        if (props.id > 1) {
            setLoading(true);
            let url = `${BASEURI}/api/dashboard/marker/paw/${props.id}/`,
                options = {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                };
            getAuthToken()
                .then(token => token)
                .catch(token => token)
                .then(token => {

                    //no token exists or all tokens are expired
                    if (token === false) {
                        setLoggedin(false);
                        return false;
                    }

                    options.headers.Authorization = `Bearer  ${token}`;


                    fetch(url, options)
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error(response.statusText, response.status);
                            }
                            return response.json();
                        })
                        .then((oneWeekData) => {
                            setOneWeekGraphData(oneWeekData);
                            setLoading(false);
                        })
                        .catch(error => {
                            // error.then(text => console.log(text));
                            console.error('Error:', error);
                            setLoading(false);
                        });
                });
        }
    }, [props.id])
    useEffect(() => {
        const renderOptions = {
            series: [
                {
                    data: oneWeekGraphData,
                    // pointStart: 1
                }
            ],
            chart: {
                type: "line"
            },
            tooltip: {
                pointFormat: `<b>{point.y}</b>`
            }
        };

        const options = Highcharts.merge(defaultOptions, renderOptions);
        if (chartContainer.current != null) {
            const chart = Highcharts.chart(chartContainer.current, options);
        }
    })

    return (
        <React.Fragment>
            {loading ?
                <img style={{ width: '24px' }} src={loadingIcon} alt='loading' /> :
                <div
                    ref={chartContainer}
                    id={props.id}
                    colSpan={2}
                >
                </div>}
        </React.Fragment>
    )
}


function MarkerDialog(props) {

    const [graphList, setGraphList] = useState([]);
    const [pawGraphsList, setPawGraphsList] = useState([]);
    const [selectedMarker, setSelectedMarker] = useState(markerTemplate);
    const [stations, setStations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [loggedin, setLoggedin] = useState();
    const [downloading, setDownloading] = useState(false);
    const [filteredFarms, setFilteredFarms] = useState([]);
    const intl = useIntl();

    //initialize graph list
    useEffect(() => {
        if (props.graphList)
            setGraphList(props.graphList);
    }, [props.graphList]);

    //initialize paw graphs list
    useEffect(() => {
        if (props.pawGraphsList)
            setPawGraphsList(props.pawGraphsList);
    }, [props.pawGraphsList]);

    //initialize selected marker
    useEffect(() => {
        if (props.selectedMarker)
            setSelectedMarker(props.selectedMarker);
    }, [props.selectedMarker])

    //initialize device/station list
    useEffect(() => {
        if (props.stations)
            setStations(props.stations);
    }, [props.stations])

    useEffect(() => {
        setFilteredFarms(props.farms)
    }, [props.farms])

    function graphItemRender(li, itemProps) {
        const itemChildren = <div> {li.props.children} [{itemProps.dataItem.calculation}] <div style={{ color: "#00F" }}> {itemProps.dataItem.chart.name} </div> </div>;
        return React.cloneElement(li, li.props, itemChildren);
    }

    function deviceItemRender(li, itemProps) {
        const itemChildren = <div> {li.props.children} [{itemProps.dataItem.serial}] </div>;
        return React.cloneElement(li, li.props, itemChildren);
    }

    function handleGraphFilterChange(event) {
        let filtered = props.graphList.filter(item => {
            let filterText = event.filter.value,
                itemText = `${item.label.toLowerCase()} ${item.chart.name.toLowerCase()} [${item.calculation.toLowerCase()}]`;

            // for (let i in filterText.split(' ')){
            //     let textSplit = filterText.split(' ')[i];
            //     // if (item.name.toLowerCase().indexOf(textSplit) !== -1 || item.device.name.toLowerCase().indexOf(textSplit) !== -1 || item.port.indexOf(textSplit) !== -1)
            //     if (itemText.indexOf(textSplit) !== -1)
            //         return true
            //     else
            //         return false
            // }
            return itemText.indexOf(filterText) !== -1
        });
        // let filtered = props.graphList.filter(item =>{
        //     let itemText = `${item.label.toLowerCase()} ${item.chart.name.toLowerCase()} [${item.calculation.toLowerCase()}]`;
        //     return itemText.includes(event.filter.value.toLowerCase())
        // })
        setGraphList(filtered);
    }

    function handlePawGraphFilterChange(event) {
        let filtered = props.pawGraphsList.filter(item => {
            let filterText = event.filter.value,
                itemText = `${item.label.toLowerCase()} ${item.chart.name.toLowerCase()} [${item.calculation.toLowerCase()}]`;

            // for (let i in filterText.split(' ')){
            //     let textSplit = filterText.split(' ')[i];
            //     // if (item.name.toLowerCase().indexOf(textSplit) !== -1 || item.device.name.toLowerCase().indexOf(textSplit) !== -1 || item.port.indexOf(textSplit) !== -1)
            //     if (itemText.indexOf(textSplit) !== -1)
            //         return true
            //     else
            //         return false
            // }
            return itemText.indexOf(filterText) !== -1
        });
        // let filtered = props.graphList.filter(item =>{
        //     let itemText = `${item.label.toLowerCase()} ${item.chart.name.toLowerCase()} [${item.calculation.toLowerCase()}]`;
        //     return itemText.includes(event.filter.value.toLowerCase())
        // })
        setPawGraphsList(filtered);
    }

    function handleFarmFilterChange(event) {
        let filtered = props.farms.filter(item => {
            let itemText = item.name.toLowerCase();
            //    return itemText.includes(event.filter.value.toLowerCase())
            return itemText.indexOf(event.filter.value.toLowerCase()) !== -1;
        })
        setFilteredFarms(filtered);
    }

    function handleStationFilterChange(event) {
        let filtered = props.stations.filter(item => {
            let filterText = event.filter.value,
                itemText = `${item.serial.toLowerCase()} ${item.name.toLowerCase()}`;

            // for (let i in filterText.split(' ')){
            //     let textSplit = filterText.split(' ')[i];
            //     // if (item.name.toLowerCase().indexOf(textSplit) !== -1 || item.device.name.toLowerCase().indexOf(textSplit) !== -1 || item.port.indexOf(textSplit) !== -1)
            //     if (itemText.indexOf(textSplit) !== -1)
            //         return true
            //     else
            //         return false
            // }
            return itemText.indexOf(filterText) !== -1;
        });

        // let filtered = props.stations.filter((item) =>{
        //     let itemText = `${item.serial.toLowerCase()} ${item.name.toLowerCase()}`;
        //     return itemText.includes(event.filter.value.toLowerCase())
        // } );

        setStations(filtered);
    }


    function handleChange(event) {
        //update selected marker fields on change
        setSelectedMarker({ ...selectedMarker, [event.target.name]: event.target.value });
        //if device has an associated marker
        //set it as selectedMarker
        if (event.target.name === 'device') {
            let marker = props.getDeviceMarker(event.target.value);
            if (marker) {
                setSelectedMarker(marker);
            } else {
                setSelectedMarker({ ...markerTemplate, device: event.target.value });
            };
        }
    }

    function download() {

        setDownloading(true);
        let url = `${BASEURI}/api/dashboard/marker/download/`,
            options = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify({ 'marker': selectedMarker.id })
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    setLoggedin(false);
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;


                fetch(url, options)
                    .then((response) => {
                        if (!response.ok) {
                            response.text().then(err => setErrorText(err));
                            throw new Error(response.statusText, response.status);
                        }

                        return response.json();
                    })
                    .then((e) => {
                        setDownloading(false);
                    })
                    .catch(error => {
                        // error.then(text => console.log(text));
                        setDownloading(false);
                        console.error('Error:', error);
                    });
            });

    }

    function createOrUpdateMarker() {
        //Remove added new marker with rigthclick
        if (selectedMarker.id < 1 || selectedMarker.id === undefined) {
            for (let i in markersObjects) {
                if (selectedMarker.id == markersObjects[i].id) {
                    markersObjects[i].setMap(null);
                };
            };
        };
        //show loading indicator
        setLoading(true);

        let dataItem = {
            marker_map: props.map.id,
            device: selectedMarker.device == null ? null : selectedMarker.device.serial,
            paw_graphs: selectedMarker.paw_graphs.map(g => g.id),
            graphs: selectedMarker.graphs.map(g => g.id),
            lng: selectedMarker.lng ? selectedMarker.lng : null,
            lat: selectedMarker.lat ? selectedMarker.lat : null,
            location_name: selectedMarker.location_name,
            farm: selectedMarker.farm ? selectedMarker.farm.id : null,
            use_custom_location: selectedMarker.use_custom_location
        }
        let url = selectedMarker.id > 1 ?
            `${BASEURI}/api/dashboard/marker/${selectedMarker.id}/`
            :
            `${BASEURI}/api/dashboard/marker/`,
            options = {
                method: selectedMarker.id > 1 ? 'PUT' : 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(dataItem)
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    setLoggedin(false);
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;


                fetch(url, options)
                    .then((response) => {
                        if (!response.ok) {
                            response.text().then(err => setErrorText(err));
                            throw new Error(response.statusText, response.status);
                        }

                        return response.json();
                    })
                    .then((newMarker) => {
                        setLoading(false);
                        props.updateMarkerList(newMarker);
                        setSelectedMarker(newMarker);
                    })
                    .catch(error => {
                        // error.then(text => console.log(text));
                        setLoading(false);
                        console.error('Error:', error);
                    });
            });
    }
    function remove() {

        if (!window.confirm(intl.formatMessage(
            {
                id: 'app.admin.map.markerDialog.confirmationMessage',
                defaultMessage: 'Are sure want to delete the station marker?'
            }
        )))
            return

        props.setMarkerDialog(false);
        if (selectedMarker.id < 1) {
            for (let i in markersObjects) {
                if (selectedMarker.id == markersObjects[i].id) {
                    markersObjects[i].setMap(null);
                };
            };
            return;
        }
        for (let i in markersObjects) {
            if (selectedMarker.id == markersObjects[i].id) {
                markersObjects[i].setMap(null);
            };
        };

        let url = `${BASEURI}/api/dashboard/marker/${selectedMarker.id}/`,
            options = {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    setLoggedin(false);
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;


                fetch(url, options)
                    .then((response) => {
                        if (!response.ok) {
                            response.text().then(err => setErrorText(err));
                            throw new Error(response.statusText, response.status);
                        }
                    })
                    .catch(error => {
                        // error.then(text => console.log(text));
                        console.error('Error:', error);
                    });
            });
    }

    return (
        <Modal>
            <Dialog>
                <div className='mdl-grid maps marker-dialog--form-container'>
                    {selectedMarker.device !== null &&
                        <div className='mdl-cell mdl-cell--12-col'>
                            <DropDownList
                                label={intl.formatMessage({ id: 'app.admin.map.markerDialog.station', defaultMessage: 'STATION' })}
                                name='device'
                                data={stations}
                                value={selectedMarker.device}
                                textField='name'
                                dataItemKey='serial'
                                onChange={selectedMarker.device == null ? '' : handleChange}
                                itemRender={deviceItemRender}
                                filterable={true}
                                onFilterChange={handleStationFilterChange}
                            />
                        </div>
                    }
                    <div className='mdl-cell mdl-cell--12-col'>
                        {selectedMarker.device === null &&
                            <Input
                                label={intl.formatMessage({ id: 'app.admin.map.markerDialog.locationName', defaultMessage: 'LOCATION NAME' })}
                                name='location_name'
                                value={selectedMarker.location_name === null ? '' : selectedMarker.location_name}
                                onChange={handleChange}
                            />}
                    </div>
                    <div className='primary mdl-cell mdl-cell--12-col'>
                        <DropDownList
                            label={intl.formatMessage({ id: 'app.admin.map.markerDialog.farm', defaultMessage: 'FARM' })}
                            name='farm'
                            data={filteredFarms}
                            value={selectedMarker.farm}
                            textField='name'
                            dataItemKey='id'
                            onChange={handleChange}
                            // itemRender={deviceItemRender}
                            filterable={true}
                            onFilterChange={handleFarmFilterChange}
                        />
                    </div>
                    <div className='mdl-cell mdl-cell--12-col'>
                        <MultiSelect
                            label={intl.formatMessage({ id: 'app.admin.map.markerDialog.pawGraphs', defaultMessage: 'PAW GRAPHS' })}
                            name='paw_graphs'
                            data={pawGraphsList}
                            value={selectedMarker.paw_graphs}
                            textField='label'
                            dataItemKey='id'
                            onChange={handleChange}
                            itemRender={graphItemRender}
                            filterable={true}
                            onFilterChange={handlePawGraphFilterChange}
                            style={{ height: 'auto' }}
                        />
                    </div>
                    <div className='mdl-cell mdl-cell--12-col'>
                        <MultiSelect
                            label={intl.formatMessage({ id: 'app.admin.map.markerDialog.moreGraphs', defaultMessage: 'MORE GRAPHS' })}
                            name='graphs'
                            key='graphs'
                            data={graphList}
                            value={selectedMarker.graphs}
                            textField='label'
                            dataItemKey='id'
                            onChange={handleChange}
                            itemRender={graphItemRender}
                            filterable={true}
                            onFilterChange={handleGraphFilterChange}
                            style={{ height: 'auto' }}
                        />
                    </div>
                    {selectedMarker.device !== null &&
                        <React.Fragment>
                            <div className='mdl-cell mdl-cell--6-col'>
                                <Input
                                    label={intl.formatMessage({ id: 'app.admin.map.markerDialog.deviceLng', defaultMessage: 'DEVICE LONGITUDE' })}
                                    name='lng'
                                    value={selectedMarker.device == null ? '' : selectedMarker.device.details && selectedMarker.device.details.location !== undefined ? selectedMarker.device.details.location.lng : ''}
                                    disabled={selectedMarker.use_custom_location}
                                />
                            </div>
                            <div className='mdl-cell mdl-cell--6-col'>
                                <Input
                                    label={intl.formatMessage({ id: 'app.admin.map.markerDialog.deviceLat', defaultMessage: 'DEVICE LATITUDE' })}
                                    name='lat'
                                    value={selectedMarker.device == null ? '' : selectedMarker.device.details && selectedMarker.device.details.location !== undefined ? selectedMarker.device.details.location.lat : ''}
                                    disabled={selectedMarker.use_custom_location}
                                />
                            </div>
                        </React.Fragment>
                    }
                    <div className='mdl-cell mdl-cell--6-col'>
                        <Input
                            label={selectedMarker.device !== null ?
                                intl.formatMessage({ id: 'app.admin.map.markerDialog.customLng', defaultMessage: 'CUSTOM LONGITUDE' }) :
                                intl.formatMessage({ id: 'app.admin.map.markerDialog.lng', defaultMessage: 'LONGITUDE' })}
                            name='lng'
                            value={selectedMarker.lng}
                            onChange={handleChange}
                            disabled={!selectedMarker.use_custom_location}
                        />
                    </div>
                    <div className='mdl-cell mdl-cell--6-col'>
                        <Input
                            label={selectedMarker.device !== null ?
                                intl.formatMessage({ id: 'app.admin.map.markerDialog.customLat', defaultMessage: 'CUSTOM LATITUDE' }) :
                                intl.formatMessage({ id: 'app.admin.map.markerDialog.lat', defaultMessage: 'LATITUDE' })}
                            name='lat'
                            value={selectedMarker.lat}
                            onChange={handleChange}
                            disabled={!selectedMarker.use_custom_location}
                        />
                    </div>
                    <div className='mdl-cell mdl-cell--6-col' style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>
                            {intl.formatMessage({ id: 'app.admin.map.markerDialog.useCustomLocation', defaultMessage: 'USE CUSTOM LOCATION' })}
                        </span>
                        <Switch
                            label='USE CUSTOM LOCATION'
                            name='use_custom_location'
                            checked={selectedMarker.use_custom_location}
                            onChange={handleChange}
                        />
                    </div>
                    <div style={{ width: '100%', margin: '8px' }} >
                        {intl.formatMessage({ id: 'app.admin.map.markerDialog.downloadForecastData', defaultMessage: 'Download forecast data' })}
                    </div>
                    <div style={{ width: '100%', margin: '0px 8px 8px 8px' }}>
                        <button
                            disabled={selectedMarker.id < 1 || selectedMarker.id === undefined && true}
                            className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-primary ag-range-btn"
                            style={{ width: '100px' }}
                            onClick={() => { download() }}>
                            {downloading ?
                                <img style={{ width: '24px' }} src={loadingWhite} alt='loading' /> :
                                intl.formatMessage({ id: 'app.admin.map.markerDialog.download', defaultMessage: 'Download' })}
                        </button>
                    </div>
                    <div style={{ padding: '10px', color: 'red' }}>{errorText}</div>
                </div>
                <DialogActionsBar>
                    <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" disabled style={{ color: 'red' }} title={intl.formatMessage({ id: 'app.admin.map.markerDialog.deleteMessage', defaultMessage: 'Cannot delete Maker.' })} onClick={remove}>DELETE</button>
                    <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={props.onClose}>
                        {intl.formatMessage({ id: 'app.admin.map.markerDialog.done', defaultMessage: 'DONE' })}
                    </button>
                    <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={createOrUpdateMarker}>
                        {selectedMarker.id > 1 ? (loading ?
                            <img style={{ width: '24px' }} src={loadingIcon} alt='loading' /> : intl.formatMessage({ id: 'app.admin.map.markerDialog.update', defaultMessage: 'UPDATE' })) :
                            (loading ? <img style={{ width: '24px' }} src={loadingIcon} alt='loading' /> : intl.formatMessage({ id: 'app.admin.map.markerDialog.create', defaultMessage: 'CREATE' }))}
                    </button>
                </DialogActionsBar>
            </Dialog>
        </Modal>
    )
}


function MapDialog(props) {

    const [map, setMap] = useState({
        options: {
            center: {
                lat: -36,
                lng: -71
            },
            type: props.map.options.type,
            zoom: 7
        }
    });
    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [loggedin, setLoggedin] = useState();


    useEffect(() => {
        setMap({ ...props.map });
    }, [props.map])

    function handleChange(event) {
        let options;
        if (event.target.name === 'lat' || event.target.name === 'lng') {
            let center = {
                ...map.options.center,
                [event.target.name]: event.target.value
            };
            options = {
                ...map.options,
                center: center
            };
        } else {
            options = {
                ...map.options,
                [event.target.name]: event.target.value
            };
        }

        setMap({ ...map, options: options });
    }


    function updateMap() {

        setLoading(true);

        let url = `${BASEURI}/api/dashboard/map/${map.id}/`,
            options = {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify({ options: map.options })
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    setLoggedin(false);
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;


                fetch(url, options)
                    .then((response) => {
                        if (!response.ok) {
                            response.text().then(err => setErrorText(err));
                            throw new Error(response.statusText, response.status);
                        }

                        return response.json();
                    })
                    .then((newMap) => {
                        setLoading(false);
                        setMap(newMap);
                        props.updateMap(newMap);
                    })
                    .catch(error => {
                        // error.then(text => console.log(text));
                        setLoading(false);
                        console.error('Error:', error);
                    });
            });
    }

    function deleteMap() {

    }

    return (
        <Modal>
            <Dialog>
                <div className='mdl-grid maps map-dialog--form-container'>
                    <div className='mdl-cell mdl-cell--12-col'>
                        <DropDownList
                            label='DEFAULT MAP TYPE'
                            name='type'
                            data={['roadmap', 'terrain', 'satellite', 'hybrid']}
                            value={map.options.type}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='mdl-cell mdl-cell--6-col'>
                        <div>
                            <label htmlFor='lng'> CENTER AT </label>
                        </div>
                        <NumericTextBox
                            label='LONGITUDE'
                            id='lng'
                            name='lng'
                            value={map.options.center.lng}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='mdl-cell mdl-cell--6-col'>
                        <div>
                            <label htmlFor='lat'> CENTER AT </label>
                        </div>
                        <NumericTextBox
                            label='LATITUDE'
                            id='lat'
                            name='lat'
                            value={map.options.center.lat}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='mdl-cell mdl-cell--6-col'>
                        <NumericTextBox
                            label='ZOOM LEVEL'
                            name='zoom'
                            value={map.options.zoom}
                            onChange={handleChange}
                        />
                    </div>
                    {/* <div className='mdl-cell mdl-cell--6-col'>
                        <div style={{padding: '10px'}}>
                            <label htmlFor='map-scroll-zoom'>ZOOM ON SCROLL</label>
                        </div>
                        <Switch label='SCROLL ZOOM' id='map-scroll-zoom'/>
                    </div> */}
                    <div style={{ color: 'red' }}>
                        {errorText}
                    </div>
                </div>
                <DialogActionsBar>
                    <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" style={{ color: 'red' }} onClick={deleteMap}>DELETE</button>
                    <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={props.onClose}>DONE</button>
                    <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={updateMap}>
                        {loading ? <img style={{ width: '24px' }} src={loadingIcon} alt='loading' /> : 'UPDATE'}
                    </button>
                </DialogActionsBar>
            </Dialog>
        </Modal>
    )
}


function GMap(props) {

    const [isMapScriptLoaded, setIsMapScriptLoaded] = useState(false);
    const [map, setMap] = useState(undefined);
    // const [stations, setStations] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [graphList, setGraphList] = useState([]);
    const [pawGraphsList, setPawGraphsList] = useState([]);
    const controlsRef = useRef(undefined);
    const contentRef = useRef(undefined);
    const filterRef = useRef(undefined);
    const [infoWindowContent, setInfoWindowContent] = useState(undefined);
    const [infoWindow, setInfoWindow] = useState(undefined);
    const [markerDialog, setMarkerDialog] = useState(false);
    const [mapDialog, setMapDialog] = useState(false);
    const [mapWidget, setMapWidget] = useState(undefined);
    const [status, setStatus] = useState(false);
    // const [markersLoaded, setMarkersLoaded] = useState(true);
    const [loggedin, setLoggedin] = useState();
    const [filterDialog, setFilterDialog] = useState(false);
    const [farms, setFarms] = useState([]);
    const [filteredFarms, setFilteredFarms] = useState([]);
    const intl = useIntl();

    const [filter, setFilter] = useState('All');

    useEffect(() => {

        setFilter(props.filter);

    }, [props.filter])

    useEffect(() => {
        filterMarkersByStatus()
    }, [filter])


    function filterMarkersByStatus() {
        if (markersObjects) {
            markersObjects.map((obj) => {
                if (filter === 'All')
                    obj.setMap(map)
                else if (obj.icon.marker_type != filter)
                    obj.setMap(null);
                else
                    obj.setMap(map)
            })
        }
    }
    function createMarkerObject(marker, isNew = false) {
        const coord = getCoordinates(marker, map);
        return new window.google.maps.Marker({
            position: coord,
            draggable: true,
            animation: isNew ? window.google.maps.Animation.BOUNCE : null,
            icon: {
                url: marker.icon,
                marker_type: marker.type,
                size: new window.google.maps.Size(30, 30),
                scaledSize: new window.google.maps.Size(30, 30),
                // origin:new window.google.maps.Point(0,0),
                anchor: new window.google.maps.Point(15, 30),
                title: marker.device == null ? marker.location_name : marker.device.name
            },
            map: map,
            station: marker.device,
            id: marker.id,
            zIndex: getZIndex(marker.type)
        });
    }
    function renderMarkers(focus = false, markersList) {
        if (!map || !isMapScriptLoaded)
            return;
        if (markersLoaded && markersList !== undefined && markersList.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            // Filter out agronic devices
            markersList.filter(item => !item.device || (item.device && item.device.api !== 'Agronic'))
                .map(station_marker => {
                    let gmap_marker = createMarkerObject(station_marker);
                    //get Position of each marker
                    let coord = getCoordinates(station_marker, map)

                    if (coord && coord.lat && coord.lng) {
                        const pos = new window.google.maps.LatLng(coord.lat, coord.lng);
                        bounds.extend(pos);
                    }
                    if (infoWindow !== undefined)
                        addListenerToMarker(gmap_marker, station_marker, station_marker.id);
                    markersObjects.push(gmap_marker);
                    return gmap_marker;
                });
            //bounds here
            if (focus || props.map.options.zoom == null || props.map.options.center.lat == null || props.map.options.center.lng == null) {
                map.fitBounds(bounds);
                map.panToBounds(bounds);
            }
            //   setMarkersLoaded(false);
            markersLoaded = false;
        }
    }

    function loadFarms() {
        let url = `${BASEURI}/api/dashboard/farm/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    setLoggedin(false);
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;


                fetch(url, options)
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error(response.statusText, response.status);
                        }
                        return response.json();
                    })
                    .then((farmList) => {
                        setFarms(farmList);
                    })
                    .catch(error => {
                        // error.then(text => console.log(text));
                        console.error('Error:', error);
                    });
            });
    }
    useEffect(() => {
        loadFarms();
    }, [])

    useEffect(() => setFilteredFarms(farms), [farms]);

    //initialize map widget
    useEffect(() => {
        if (props.map)
            setMapWidget(props.map);
    }, [props.map]);

    //load device markers for primary map
    useEffect(() => {
        // let url = BASEURI + `/api/dashboard/marker/primary/`,
        // options = {
        //     method:'GET',
        //     headers: {
        //         "Content-Type": "application/json; charset=utf-8",
        //     },
        // };

        // getAuthToken()
        // .then(token => token)
        // .catch(token => token)
        // .then(token => {

        //     //no token exists or all tokens are expired
        //     if (token === false){
        //         setLoggedin(false);
        //         return false;
        //     }

        //     options.headers.Authorization = `Bearer  ${token}`;

        //     fetch(url, options)
        //     .then((response) => {
        //         return response.json();
        //     })
        //     .then((device_markers) => {
        //         if (device_markers){
        //             markersLoaded = true;
        //             setMarkers(device_markers);
        //         }
        //     })
        //     .catch(error => {
        //         console.error('Error:', error.code);  
        //     });
        // });
        if (props.markers) {
            markersLoaded = true;
            setMarkers(props.markers);
        }
    }, [props.markers])


    //load paw graphs list for paw graph select boxes
    useEffect(() => {
        let url = `${BASEURI}/api/allgraphs/?graph_type=paw`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((graphList) => {
                        if (graphList.length > 0)
                            setPawGraphsList(graphList);
                    })
                    .catch(error => console.error('Error:', error));

            });
    }, [])

    //load graphs list for graph select boxes
    useEffect(() => {
        let url = `${BASEURI}/api/allgraphs/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((graphList) => {
                        if (graphList.length > 0)
                            setGraphList(graphList);
                    })
                    .catch(error => console.error('Error:', error));

            });
    }, [])



    //load google maps script
    useEffect(() => {
        if (isMapScriptLoaded)
            return;

        const mapEl = document.getElementById('googlescript');
        if (mapEl) {
            setIsMapScriptLoaded(true)
            return
        }

        const scriptEl = document.createElement("script");
        scriptEl.id = 'googlescript';
        scriptEl.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyD6QFn_rnwHupKdG4-iFnLNZsvhBQ0jw_M';

        scriptEl.onload = e => {
            setIsMapScriptLoaded(true);
        };
        document.body.appendChild(scriptEl);

        // return () => scriptEl.remove();
    }, []);

    //create google map object after google maps
    //script is loaded
    useEffect(() => {
        if (!isMapScriptLoaded)
            return;

        let center, zoom, type;
        try {
            center = mapWidget.options.center;
        } catch {
            center = { lat: -77, lng: -33 }
        }
        try {
            zoom = mapWidget.options.zoom;
        } catch {
            zoom = 5;
        }
        try {
            type = mapWidget.options.type;
        } catch {
            type = 'hybrid';
        }

        let mapObject = new window.google.maps.Map(
            document.getElementById('map'),
            {
                center: center,
                zoom: zoom,
                scrollwheel: false,
                mapTypeId: type,
                gestureHandling: 'greedy'
            });
        setMap(mapObject);

        mapObject.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(controlsRef.current);
        if (window.screen.width > 400) {
            mapObject.controls[window.google.maps.ControlPosition.TOP_LEFT].push(filterRef.current);
        } else {
            mapObject.controls[window.google.maps.ControlPosition.BOTTOM_LEFT].push(filterRef.current);
        }

    }, [isMapScriptLoaded, status]);

    useEffect(() => {
        //Prevent map from unnecessary refresh
        if (mapWidget === undefined) {
            setStatus(!status);
        }
    });
    // useEffect(() => {
    //     setStations(props.stations.all);
    // }, [props.stations]);
    function addListenerToMarker(marker, template, id, bounce = false) {
        marker.addListener('click', (e) => {
            if (bounce) {
                marker.setAnimation(null);
            };

            infoWindow.open(map, marker);
            getMarkerData({ ...template, id: id, device: id >= 1 ? template.device : null, lat: e.latLng.lat(), lng: e.latLng.lng() })
        });

        marker.addListener('drag', (e) => {
            infoWindow.close();
            getMarkerData({ ...template, id: id, device: id >= 1 ? template.device : null, lat: e.latLng.lat(), lng: e.latLng.lng() });
        });
    }
    function addMarker(event = false) {
        const position = event ? { lat: event.latLng.lat(), lng: event.latLng.lng() } : { lat: map.getCenter().lat(), lng: map.getCenter().lng() };
        const marker = {
            ...markerTemplate,
            id: Math.random(),
            ...position,
            location_name: 'Forecast',
        }
        let gmap_marker = createMarkerObject(marker, true);
        if (infoWindow !== undefined)
            addListenerToMarker(gmap_marker, markerTemplate, marker.id, true);
        markersObjects.push(gmap_marker);
    };
    useEffect(() => {
        // add new marker object to map an push it markersObjects
        if (map != undefined) {
            map.addListener('rightclick', (e) => {
                addMarker(e);
            })
        };

    }, [map]);

    useEffect(() => {
        if (isMapScriptLoaded)
            setInfoWindow(new window.google.maps.InfoWindow({
                content: '<h1></h1>'
            }));
    }, [isMapScriptLoaded]);

    //refresh info window content on marker change
    useEffect(() => {
        if (infoWindow !== undefined)
            infoWindow.setContent(contentRef.current);
    }, [infoWindowContent]);

    //generate google maps markers for each station/device
    //represented by a 'marker'
    useEffect(() => {
        renderMarkers(false, markers);
    }, [map, markers, isMapScriptLoaded]);

    function updateMapMarkers(marker) {
        for (let i in markersObjects) {
            if (markersObjects[i].id == marker.id) {
                markersObjects[i].setPosition(getCoordinates(marker, map))
                markersObjects[i].setIcon(
                    {
                        url: marker.icon,
                        marker_type: marker.type,
                        size: new window.google.maps.Size(30, 30),
                        scaledSize: new window.google.maps.Size(30, 30),
                        // origin:new window.google.maps.Point(0,0),
                        anchor: new window.google.maps.Point(15, 30),
                        title: marker.device == null ? marker.location_name : marker.device.name,
                        zIndex: getZIndex(marker.type)
                    }
                );
                // Update infowindow content for paw and chart
                window.google.maps.event.clearListeners(markersObjects[i], 'click');
                window.google.maps.event.clearListeners(markersObjects[i], 'drag');
                addListenerToMarker(markersObjects[i], marker, marker.id);
            };

        };
    };

    //reset info window content for current marker
    //and get paw graph data for sparline chart
    function getMarkerData(marker) {
        setInfoWindowContent(marker);
    }

    //Set z-index of markers according to icon
    function getZIndex(type) {
        if (type === 'GRAY')
            return 10000
        else if (type === 'YELLOW')
            return 20000
        else if (type === 'GREEN')
            return 100000
        else if (type === 'BLUE')
            return 1000000
        else if (type === 'RED')
            return 9999999
    }


    //setup new marker
    function setNewMarker() {
        setInfoWindowContent(markerTemplate);
        setMarkerDialog(true);
    }

    function updateMarkerList(marker) {

        let tempMarkers = markers.slice();
        for (let i in tempMarkers) {
            if (marker.id === tempMarkers[i].id) {
                // tempMarkers[i].setMap(null);
                tempMarkers.splice(i, 1, marker);
                setMarkers(tempMarkers);
                setInfoWindowContent(marker);
                updateMapMarkers(marker);
                return;
            }
        }
        // add the new marker to map which is saved to database
        let gmap_marker = createMarkerObject(marker);
        if (infoWindow !== undefined)
            addListenerToMarker(gmap_marker, marker, marker.id, true);

        //push it to markersObjects for futher manipulation
        markersObjects.push(gmap_marker);

        tempMarkers.push(marker);
        setMarkers(tempMarkers);
    }

    function getDeviceMarker(device) {
        for (let i in markers) {
            try {
                if (device.serial === markers[i].device.serial)
                    return markers.slice(i, i + 1)[0];
            } catch {

            }
        };
    }

    function updateMap(newMap) {
        setMapWidget(newMap);
        map.setCenter(newMap.options.center);
        map.setZoom(newMap.options.zoom);
        map.setMapTypeId(newMap.options.type);
    }
    function handleFarmFilterChange(event) {
        let filtered = farms.filter(item => {
            let itemText = item.name.toLowerCase();
            //    return itemText.includes(event.filter.value.toLowerCase())
            return itemText.indexOf(event.filter.value.toLowerCase()) !== -1;
        })
        setFilteredFarms(filtered);
    }

    function handleFarmChange(event) {
        const id = parseInt(event.target.value);

        markersLoaded = true;
        deleteMarkers();

        if (id === 0)
            renderMarkers(true, markers);
        else
            renderMarkers(
                true,
                markers.filter(item => item.farm && item.farm.id === id)
            )
    }

    // Remove all markers from map to set new markers
    function deleteMarkers() {

        for (let i in markersObjects) {
            markersObjects[i].setMap(null);
        }
        markersObjects = [];
    }

    return (
        <div className='mdl-cell mdl-grid--no-spacing mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--4-col-phone mdl-shadow--2dp'
            style={{ 'position': 'relative' }}
        >
            {/* custom controls html */}
            <div ref={controlsRef} style={{ margin: '10px', background: 'white', borderRadius: '2px' }}>
                <div className='map-control'>
                    <div title={intl.formatMessage({ id: 'app.admin.map.addStationMarker', defaultMessage: 'Add Station Marker' })} onClick={() => setNewMarker()}>
                        <i className='material-icons'>add_location</i>
                    </div>
                </div>
                <div className='map-control'>
                    <div title={intl.formatMessage({ id: 'app.admin.map.mapSettings', defaultMessage: 'Map Settings' })} onClick={() => {
                        setMapWidget({
                            ...mapWidget,
                            options: {
                                zoom: map.getZoom(),
                                center: {
                                    lat: map.getCenter().lat(),
                                    lng: map.getCenter().lng()
                                },
                                type: map.getMapTypeId()
                            }
                        });
                        setMapDialog(true);

                    }}>
                        <i className='material-icons'>settings</i>
                    </div>
                </div>
                <div className='map-control'>
                    <div title={intl.formatMessage({ id: 'app.admin.map.addForecastMarker', defaultMessage: 'Add Forecast Marker' })} onClick={() => addMarker()}>
                        {/* <i className='material-icons'>delete_outline</i> */}
                        <img src={forecast_station} alt="forecast_station" style={{ width: '30px', display: 'inline-block' }} />
                    </div>
                </div>
                <div className='map-control'>
                    <div title={intl.formatMessage({ id: 'app.admin.map.manageFarms', defaultMessage: 'Manage Farms' })} onClick={() => setFilterDialog(true)}>
                        <img src={farmLand} style={{ width: '25px', display: 'inline-block' }} />
                    </div>
                </div>
            </div>
            <div ref={filterRef} style={{ margin: '10px', background: 'white', borderRadius: '2px' }}>
                <div className='map-control' style={{ height: '40px', display: "flex", alignItems: 'center', }}>
                    {/* <DropDownList 
                        style={{height:'28px'}}
                        label='FARMS'
                        name='farms'
                        data={filteredFarms}
                        onChange={handleFarmChange} 
                        onFilterChange={handleFarmFilterChange}
                        dataItemKey='id'
                        textField='name'
                        filterable={true}

                    /> */}

                    <select
                        title={intl.formatMessage({
                            id: 'app.admin.map.filterByFarm',
                            defaultMessage: 'Filter by farm'
                        }
                        )}
                        onChange={handleFarmChange}
                        style={{
                            margin: '3px',
                            border: 'none',
                            fontWeight: '3px',
                            maxWidth: '150px'
                        }}
                    >

                        <option value='0'>
                            {intl.formatMessage({
                                id: 'app.admin.map.showAll',
                                defaultMessage: 'Show all'
                            })
                            }
                        </option>

                        {filteredFarms.map((item) => <option key={item.id} value={item.id}>
                            {item.name}
                        </option>)}

                    </select>
                    <select
                        title={intl.formatMessage({
                            id: 'app.admin.map.filterByStatus',
                            defaultMessage: 'Filter by status'
                        })}
                        onChange={(e) => setFilter(e.target.value)}
                        style={{
                            margin: '3px',
                            border: 'none',
                            fontWeight: '3px',
                            maxWidth: '150px'
                        }}
                    >
                        <option value='All'>
                            {intl.formatMessage({ id: 'app.admin.map.showAll', defaultMessage: 'Show all' })}
                        </option>
                        <option value='RED'>
                            {intl.formatMessage({ id: 'app.admin.map.severeStress', defaultMessage: 'Severe stress' })}
                        </option>
                        <option value='YELLOW'>
                            {intl.formatMessage({ id: 'app.admin.map.startStress', defaultMessage: 'Start stress' })}
                        </option>
                        <option value='GREEN'>
                            {intl.formatMessage({ id: 'app.admin.map.optimal', defaultMessage: 'Optimal' })}
                        </option>
                        <option value='BLUE'>
                            {intl.formatMessage({ id: 'app.admin.map.excessOfWater', defaultMessage: 'Excess of water' })}
                        </option>
                        <option value='GRAY'>
                            {intl.formatMessage({ id: 'app.admin.map.weatherStations', defaultMessage: 'Weather stations' })}
                        </option>
                    </select>
                </div>
            </div>
            {/* end custom controls */}

            {/* map div element */}
            {props.insideMap &&
                <div style={{ position: 'absolute', bottom: '-25px', left: '5px', zIndex: 99, width: '450px' }}>
                    {props.graphPieBox}
                </div>
            }
            <div id='map' style={{ width: '100%', minHeight: '75vh', height: '100%' }}></div>

            {/* info window content */}
            <div style={{ display: 'none' }}>
                <div ref={contentRef}>
                    {infoWindowContent &&
                        <div className='maps info-window'>
                            <div style={{ fontSize: '20px' }}>{infoWindowContent.device == null ? infoWindowContent.location_name : <React.Fragment>{infoWindowContent.device.name} [{infoWindowContent.device.serial}]</React.Fragment>}</div>
                            <SparkLine
                                id={infoWindowContent.id}
                            />
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        {infoWindowContent.device == null ? null :
                                            <React.Fragment>
                                                <td>
                                                    {intl.formatMessage({ id: 'app.admin.map.infoWindow.bat', defaultMessage: 'BAT' })}
                                                    {infoWindowContent.device.is_battery_low ? <i className='material-icons'>battery_alert</i> : null}
                                                </td>
                                                <td> {
                                                    infoWindowContent.device.details ? infoWindowContent.device.details.battery : null
                                                } % </td>
                                            </React.Fragment>
                                        }
                                    </tr>
                                    <tr>
                                        <td>
                                            {intl.formatMessage({ id: 'app.admin.map.infoWindow.paw', defaultMessage: 'PAW' })}
                                        </td>
                                        <td> {infoWindowContent.avg_paw} % </td>
                                    </tr>
                                    {infoWindowContent.graphs.map(g => <tr key={g.id}><td>{g.label}</td><td>{g.last_value[1]}</td></tr>)}
                                    {infoWindowContent.device == null ? null :
                                        <tr>
                                            <td>
                                                {intl.formatMessage({ id: 'app.admin.map.infoWindow.users', defaultMessage: 'USERS' })}
                                            </td>
                                            <td>
                                                {infoWindowContent.device.users.map(u => <div>{u.username}</div>)}
                                            </td>
                                        </tr>
                                    }
                                    {infoWindowContent.device == null ? null :
                                        <React.Fragment>
                                            <tr>
                                                <td>
                                                    {intl.formatMessage({ id: 'app.admin.map.infoWindow.plot', defaultMessage: 'PLOT' })}
                                                </td>
                                                <td style={{ fontSize: '.8em' }}>{infoWindowContent.device.plot}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {intl.formatMessage({ id: 'app.admin.map.infoWindow.crop', defaultMessage: 'CROP' })}
                                                </td>
                                                <td style={{ fontSize: '.8em' }}>{infoWindowContent.device.crop}</td>
                                            </tr>
                                        </React.Fragment>
                                    }
                                </tbody>
                            </table>

                            <div style={{ textAlign: 'right' }}>
                                <button onClick={() => setMarkerDialog(true)} className='mdl-button mdl-js-button mdl-button--icon mdl-button--colored'>
                                    <i className='material-icons'>edit_attributes</i>
                                </button>
                            </div>
                        </div>}
                </div>
            </div>

            {/* dialog to add/edit map markers */}
            {markerDialog && <MarkerDialog
                onClose={() => setMarkerDialog(false)}
                stations={props.stations}
                graphList={graphList}
                pawGraphsList={pawGraphsList}
                selectedMarker={infoWindowContent}
                updateMarkerList={updateMarkerList}
                map={mapWidget}
                getDeviceMarker={getDeviceMarker}
                setMarkerDialog={setMarkerDialog}
                mapCenter={map.getCenter()}
                farms={farms}
            />}
            {filterDialog && <FilterMarkers
                onClose={() => setFilterDialog(false)}
                markersList={markers}
                loadFarms={loadFarms}
                farms={farms}
            />}
            {/* dialog to edit map settings */}
            {mapDialog && <MapDialog
                onClose={() => setMapDialog(false)}
                map={mapWidget}
                updateMap={updateMap}
            />}
        </div>
    );
}

export default GMap;
export { MapContainer };