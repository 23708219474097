import React, { Component } from 'react';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { getAuthToken, BASEURI } from '../utils';
import { KCForm } from './ETcForm';
import { TagSlider } from './Slider';

class ETcRawSensorForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            graphName: '',
            sensors: [],
            extracts: [],
            extract: null,
            paw_guides: false,
            pawGuides: null,
            color: '#000000'
        };

        this.setInitialValues = this.setInitialValues.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.itemRender = this.itemRender.bind(this);
    }

    setInitialValues() {
        this.setState(this.props.initialValues);
        this.setState(this.props.options);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.name === 'graphType' || event.target.name === 'paw_guides' ? event.target.value.value : event.target.value,
            pawGuides: event.target.name === 'paw_guides' ? event.target.value : this.setState.pawGuides
        });

        if (event.target.name === 'sensors') {
            if (event.target.value.length > 0) {

                let url = `${BASEURI}/api/extracts/${event.target.value[0].id}`,

                    options = {
                        method: 'GET',
                        headers: {
                            "Content-Type": "application/json; charset=utf-8",
                        }
                    };

                getAuthToken()
                    .then(token => token)
                    .catch(token => token)
                    .then(token => {

                        options.headers.Authorization = `Bearer  ${token}`;

                        fetch(url, options)
                            .then((response) => response.json())
                            .then((extracts) => {
                                this.setState({
                                    extracts: extracts
                                });
                            })
                            .catch(error => console.log('Error:', error));
                    });
            }
        }
    }

    itemRender(li, itemProps) {

        const itemChildren = <div> {li.props.children} <div style={{ color: "#00F" }}> {itemProps.dataItem.device.name} [{itemProps.dataItem.port}]</div> </div>;
        return React.cloneElement(li, li.props, itemChildren);
    }

    graphTypes = {
        line: { text: 'Line', value: 'line' },
        column: { text: 'Bar', value: 'column' }
    }

    resampleFnValues = {
        sum: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.sum', defaultMessage: 'Sum' }), value: 'sum' },
        //'avg' should be changed to 'mean' after
        // all graph with 'avg' in db are changed to 'mean'
        avg: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.Mean', defaultMessage: 'Mean' }), value: 'avg' },
        min: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.min', defaultMessage: 'Min' }), value: 'min' },
        max: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.max', defaultMessage: 'Max' }), value: 'max' },
        diff: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.diff', defaultMessage: 'Diff' }), value: 'diff' }
    }

    resampleValues = {
        null: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.doNotResample', defaultMessage: 'Do not resample' }), value: null },
        H: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.hourly', defaultMessage: 'Hourly' }), value: 'H' },
        D: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.daily', defaultMessage: 'Daily' }), value: 'D' },
        '7D': { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.7Day', defaultMessage: '7-Day' }), value: '7D' },
        W: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.weekly', defaultMessage: 'Weekly' }), value: 'W' },
        '15D': { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.15Day', defaultMessage: '15-Day' }), value: '15D' },
        '2W': { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.biWeekly', defaultMessage: 'Bi-Weekly' }), value: '2W' },
        M: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.monthly', defaultMessage: 'Monthly' }), value: 'M' },
        Y: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.yearly', defaultMessage: 'Yearly' }), value: 'Y' }
    }

    render() {

        return (
            <div className='modal-form-inputs-wrap'>
                <div className="modal-form-inputs-wrap-row">
                    <MultiSelect key='sensor-select'
                        listNoDataRender={this.props.loadingSensors ? this.props.noData : null}
                        name='sensors'
                        required={true}
                        label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.sensor', defaultMessage: 'SENSOR(S)' })}
                        data={this.props.sensors}
                        value={this.props.graph.sensors}
                        textField='name'
                        dataItemKey='id'
                        itemRender={this.itemRender}
                        onChange={this.props.handleChange}
                        filterable={true}
                        onFilterChange={this.props.handleFilterChange}
                        style={{ height: 'auto' }}
                    />
                    <MultiSelect
                        key='extract-select'
                        name='extract'
                        required={true}
                        label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.sensorExtract', defaultMessage: 'SENSOR EXTRACT' })}
                        data={this.props.extracts}
                        value={this.props.graph.extract}
                        textField='description'
                        dataItemKey='id'
                        onChange={this.props.handleChange}
                        style={{ height: 'auto' }} />
                </div>
                <div className="modal-form-inputs-wrap-row">
                    <Input
                        // required={true}
                        name='formula'
                        label={this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.formula', defaultMessage: 'FORMULA' })}
                        value={this.props.graph.variables.formula}
                        onChange={this.props.handleChange} />
                    <DropDownList
                        label={this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.resampleFreq', defaultMessage: 'RESAMPLE FREQ' })}
                        name='resample'
                        data={[
                            { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.doNotResample', defaultMessage: 'Do not resample' }), value: null },
                            { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.hourly', defaultMessage: 'Hourly' }), value: 'H' },
                            { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.daily', defaultMessage: 'Daily' }), value: 'D' },
                            { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.7Day', defaultMessage: '7-Day' }), value: '7D' },
                            { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.weekly', defaultMessage: 'Weekly' }), value: 'W' },
                            { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.15Day', defaultMessage: '15-Day' }), value: '15D' },
                            { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.biWeekly', defaultMessage: 'Bi-Weekly' }), value: '2W' },
                            { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.monthly', defaultMessage: 'Monthly' }), value: 'M' },
                            { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.yearly', defaultMessage: 'Yearly' }), value: 'Y' }
                        ]}
                        textField='text'
                        dataItemKey='value'
                        value={this.resampleValues[this.props.graph.resample]}
                        onChange={this.props.handleChange} />
                </div>
                <div className="modal-form-inputs-wrap-row">
                    <DropDownList
                        label={this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.resampleFn', defaultMessage: 'RESAMPLE FN' })}
                        name='resample_fn'
                        data={[
                            { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.sum', defaultMessage: 'Sum' }), value: 'sum' },
                            { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.Mean', defaultMessage: 'Mean' }), value: 'avg' },
                            { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.min', defaultMessage: 'Min' }), value: 'min' },
                            { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.max', defaultMessage: 'Max' }), value: 'max' },
                            { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.diff', defaultMessage: 'Diff' }), value: 'diff' }
                        ]}
                        textField='text'
                        dataItemKey='value'
                        value={this.resampleFnValues[this.props.graph.resample_fn]}
                        onChange={this.props.handleChange} />
                    {/* <div></div> */}
                </div>
                <KCForm
                    intl={this.props.intl}
                    graph={this.props.graph}
                    handleChange={this.props.handleChange}
                    handleValuesChange={this.props.handlePlantValueChange}
                    setPlantValues={this.props.setPlantValues} />
                <div className="modal-form-inputs-wrap-row">
                    <DropDownList
                        label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.chartType', defaultMessage: 'CHART TYPE' })}
                        name='_type'
                        data={[{ text: 'Line', value: 'line' }, { text: 'Bar', value: 'column' }]}
                        textField='text'
                        dataItemKey='value'
                        value={this.graphTypes[this.props.graph._type]}
                        onChange={this.props.handleChange} />
                    <Input
                        type='color'
                        label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.color', defaultMessage: 'COLOR' })}
                        name='color'
                        data={this.state.color}
                        value={this.props.graph.color}
                        onChange={this.props.handleChange} />
                </div>
                <div className="modal-form-inputs-wrap-row">
                    <Input
                        required={true}
                        name='label'
                        label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.label', defaultMessage: 'LABEL' })}
                        value={this.props.graph.label}
                        onChange={this.props.handleChange} />
                    <Input
                        name='axis_min'
                        label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.axisMin', defaultMessage: 'AXIS MIN' })}
                        value={this.props.graph.axis_min !== null ? this.props.graph.axis_min : ''}
                        onChange={this.props.handleChange} />
                </div>
                <div className="modal-form-inputs-wrap-row">
                    <Input
                        name='axis_max'
                        label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.axisMax', defaultMessage: 'AXIS MAX' })}
                        value={this.props.graph.axis_max !== null ? this.props.graph.axis_max : ''}
                        onChange={this.props.handleChange} />
                    <Input
                        required={true}
                        name='y_axis_legend'
                        label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.yAxisLegend', defaultMessage: 'Y AXIS LEGEND' })}
                        value={this.props.graph.y_axis_legend}
                        onChange={this.props.handleChange} />
                </div>
                <div className="modal-form-inputs-wrap-row">
                    <div style={{ marginTop: '20px', color: 'gray' }}>
                        <span style={{ paddingRight: '20px' }}>
                            {this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.dataLabels', defaultMessage: 'DATA LABELS' })}
                        </span>
                        <Switch
                            name='data_labels'
                            checked={this.props.graph.data_labels}
                            onChange={this.props.handleChange}
                        />
                    </div>
                    <div></div>
                </div>
                <div className="modal-form-inputs-wrap-row">
                    <div className="range-slider">
                        <TagSlider
                            setPlantDays={this.props.setPlantDays}
                            graph={this.props.graph}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default ETcRawSensorForm;