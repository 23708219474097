import React, { Component } from 'react';


class Settings extends Component {
  render(){

    return <div>Settings </div>;
  }
    
}



export default Settings;